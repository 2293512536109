import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDailogOpen: false,
};

const schedulerTechnicianViewSlice = createSlice({
  name: 'schedulerTechnicianView',
  initialState: initialState,
  reducers: {
    setFilterTechinicianView(state, { payload }) {
      state.isDailogOpen = payload.hasOwnProperty('isDailogOpen')
        ? payload.isDailogOpen
        : state.isDailogOpen;
    },
  },
});

export const { setFilterTechinicianView } =
  schedulerTechnicianViewSlice.actions;

export const schedulerTechnicianViewReducer =
  schedulerTechnicianViewSlice.reducer;
