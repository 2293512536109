import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';

import PFButton from '../../shared/PFPrime/PFButton';
import PFInputText from '../../shared/PFPrime/PFInputText';

const SaveCustomFilter = ({
  addCustomFilterVisible,
  setAddCustomFilterVisible,
  customFilterFormik,
  loading,
  dropdownError,
  setDropdownError,
}) => {
  return (
    <Dialog
      header="Custom Filters"
      visible={addCustomFilterVisible}
      style={{ width: '30vw' }}
      onHide={() => {
        customFilterFormik.handleReset();
        setAddCustomFilterVisible(false);
        setDropdownError(false);
      }}
      draggable={false}
    >
      <div className="card w-12">
        <form
          onSubmit={customFilterFormik.handleSubmit}
          className="flex flex-column gap-2"
        >
          <div className="grid mt-2">
            <div className="col-12 p-0">
              <span className="p-float-label h-full">
                <PFInputText
                  id="filter_name"
                  maxLength={200}
                  value={customFilterFormik?.values?.filter_name}
                  onChange={e => {
                    customFilterFormik.setFieldValue(
                      'filter_name',
                      e?.target?.value
                    );
                  }}
                  className={`w-full h-full ${
                    customFilterFormik.touched?.filter_name &&
                    customFilterFormik.errors?.filter_name
                      ? 'p-invalid'
                      : ''
                  }`}
                ></PFInputText>
                <label htmlFor="filter_name" className="text-base">
                  Name
                </label>
              </span>
            </div>
            <div className="col-12 p-0 flex">
              <div className="col-6 flex align-items-center gap-2">
                <InputSwitch
                  checked={customFilterFormik.values.isActive}
                  onChange={e => {
                    customFilterFormik.setFieldValue('isActive', e.value);
                  }}
                  className="mt-1 text-xs"
                />
                <label className="pt-1 ml-1 text-base">
                  {customFilterFormik.values.isActive ? ` Active` : `Inactive`}{' '}
                </label>
              </div>
              <div className="col-6 px-0">
                {dropdownError && (
                  <div className="p-error text-xs">
                    Please select at least one dropdown value.
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-content-end">
            <PFButton
              label="Save"
              type="submit"
              disabled={!customFilterFormik.isValid || loading}
              icon={loading ? 'pi pi-spin pi-spinner' : ''}
              pt={{
                label: {
                  className: 'flex-none pr-1 m-0',
                },
              }}
              className="flex flex-row-reverse"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default SaveCustomFilter;
