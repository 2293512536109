import './css/index.css';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
  Day,
  Week,
  Month,
  TimelineMonth,
  TimelineYear,
} from '@syncfusion/ej2-react-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Tooltip,
  DialogContentText,
  TextField,
  Typography,
  Modal,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { BlockUI } from 'primereact/blockui';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';

import GenericDialog from '../../shared/Dialog/GenericDialog';
import { EVENT_COLORS, EVENT_TYPES } from '../constants/constants';
import TechnicianAvatar from '../TechnicianAvatar';
import {
  checkPermission,
  ellipsisString,
  getPercentageValue,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import ProjectScheduleCard from '../ProjectScheduleCard';
import {
  firstRowData,
  SchedulerSkeleton,
  secondRowData,
} from '../../shared/Loader/schedulerSkeleton';
import {
  DEFAULT_COLORS_BY_PROJECT_TYPE,
  PROJECT_REPORT,
  scheduleInfoUserCustomAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
} from '../../../constants';
import { setInstallerViewCalendar } from '../../../redux/slices/installer-view-calendar.slice';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { addNotes } from '../../ProjectManagement/Notes/Notes.service';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import { REACT_APP_SYNCFUSION_LICENCE_KEY } from '../../../constants/envConstants';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { eventColorGenerator } from '../new-scheduler/color-codes';

import InstallerViewBlockCalendar from './InstallerViewBlockCalendar';
import { useStyles } from './css/CalenderContainer.styles';
import {
  getCalendarData,
  mapProjectTypeToColor,
  updateInstallerSchedule,
} from './services/calendar';
import {
  getDefaultView,
  getPeriod,
  selectedRapidSlotSyncFusion,
} from './helpers';

registerLicense(`${REACT_APP_SYNCFUSION_LICENCE_KEY}`);
const DEFAULT_DISABLED_TIME_SCALE = { enable: false };
const DEFAULT_WORK_DAYS = [1, 2, 3, 4, 5];
const ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];
const REFRESH_ICON = {
  id: 'refreshIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Refresh',
  cssClass:
    'material-icons-sharp md-28 md-refresh hover:text-xl px-2 refreshIconScheduler',
};
const FULLSCREEN_ICON = {
  id: 'fullscreenIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Fullscreen',
  cssClass:
    'material-icons-sharp md-28 md-fullscreen hover:text-xl px-2 fullscreenIconScheduler',
};
const SchedulerCalendar = ({
  calendarRef,
  targetID,
  filter,
  data: parentData,
  loader,
  handleSetFilter,
  handleSetLoader,
  statusOptions,
  setCalendarFullScreen,
  view,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isWeekendsVisible, selectedInstallers } = useSelector(
    state => state.newScheduler
  );
  const [defaultView, setDefaultView] = useState(
    getDefaultView() || 'TimelineDay'
  );
  const [isNavigate, setIsNavigate] = useState(false);
  const readOnly = true;
  const [calendarData, setCalendarData] = useState({
    resourceData: [],
    eventData: [],
    calendarBlock: false,
    calendarFilter: filter?.calendarFilter || {},
  });
  const splitAndAddEvents = (StartTime, EndTime, eventData, eventDataLocal) => {
    if (StartTime && EndTime) {
      StartTime = new Date(StartTime);
      EndTime = new Date(EndTime);
      const isMultiDay =
        new Date(StartTime).toDateString() !== new Date(EndTime).toDateString();
      if (!isMultiDay || calendarRef?.current?.currentView !== 'Day')
        eventDataLocal.push(eventData);
      else {
        let currentDate = new Date(StartTime);
        // Start day with specific start time until end of the start day
        eventDataLocal.push({
          ...eventData,
          StartTime: StartTime,
          EndTime: new Date(currentDate.setHours(23, 59, 59, 999)), // End of start day
          isOverlapping: true,
          actualStartTime: StartTime,
          actualEndTime: EndTime,
        });
        // Loop for full middle days
        currentDate = new Date(StartTime);
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0, 0, 0, 0);
        while (currentDate.toDateString() !== EndTime.toDateString()) {
          const nextDate = new Date(currentDate);
          nextDate.setHours(23, 59, 59, 999);
          eventDataLocal.push({
            ...eventData,
            StartTime: new Date(currentDate),
            EndTime: new Date(nextDate),
            isOverlapping: true,
            actualStartTime: StartTime,
            actualEndTime: EndTime,
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // End day with specific end time
        eventDataLocal.push({
          ...eventData,
          StartTime: new Date(currentDate),
          EndTime: EndTime,
          isOverlapping: true,
          actualStartTime: StartTime,
          actualEndTime: EndTime,
        });
      }
    } else {
      eventDataLocal.push(eventData);
    }
  };
  const callGetCalendarDataApi = async (doLoadResource, localFilters) => {
    let tempCalendarData = {};
    try {
      const params = {
        ...filter.calendarFilter,
        date: calendarRef?.current?.selectedDate
          ? calendarRef.current.selectedDate
          : moment().format(),
        period: calendarRef?.current?.currentView
          ? getPeriod(calendarRef.current.currentView)
          : getPeriod(defaultView),
        ...(localFilters || {}),
      };
      doLoadResource &&
        setCalendarData({
          ...calendarData,
          calendarBlock: true,
        });
      const apiResponse = await getCalendarData(params);
      console.log(new Date());
      console.log('STARTinggg', apiResponse);
      if (
        Array.isArray(apiResponse?.data?.items) &&
        apiResponse?.data?.items.length
      ) {
        const timeoffApprovedEventsLocal = {};
        const timeoffTentativeEventsLocal = {};
        const blockedEventsLocal = {};
        const eventDataLocal = [];
        const resourceDataLocal = {};
        let index = 1;
        for (const cal of apiResponse.data.items) {
          let timeoffApprovedEventsAvailability = {};
          let timeoffTentativeEvents = {};
          let blockedEvents = {};
          const key = `${cal.time_off_start_date}__${cal.time_off_end_date}`;
          if (
            cal?.time_off_start_date &&
            cal?.time_off_end_date &&
            cal?.is_blocked_time !== 1
          ) {
            const time_off_start_date = cal.time_off_start_date;
            const time_off_end_date = cal.time_off_end_date;
            const unformattedEndDate = moment(time_off_start_date);
            const endDateHour = moment(time_off_end_date).get('hour');
            const endDateMinute = moment(time_off_end_date).get('minute');
            const endDate = unformattedEndDate.set({
              hour: endDateHour,
              minute: endDateMinute,
            });
            const startDate = moment(time_off_start_date);
            const durationInHour = moment.duration(endDate.diff(startDate));
            const splitHours = durationInHour.asHours().toString().split('.');
            const unformattedHours = splitHours[0];
            let minute = '00';

            if (splitHours.length > 1) {
              minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
            }
            const duration = unformattedHours
              .padStart(2, '0')
              .concat(':', minute);
            const availabilityUpdate = {
              title: cal?.time_off_note || '',
              resourceId: cal?.installer_id,
              border: 'none',
              installer_id: cal?.installer_id,
              project_id: cal?.project_id,
              start: cal?.time_off_start_date
                ? moment(cal?.time_off_start_date).format()
                : '',
              end: cal?.time_off_end_date
                ? moment(cal?.time_off_end_date).format()
                : '',
              allDay: cal?.full_day == 1 ? true : false,
              eventType: 'timeoff',
              rrule: {
                freq: 'weekly',
                interval: cal?.is_recurring_every_other_week,
                byweekday: [
                  ...(cal?.sun ? ['su'] : []),
                  ...(cal?.mon ? ['mo'] : []),
                  ...(cal?.tue ? ['tu'] : []),
                  ...(cal?.wed ? ['we'] : []),
                  ...(cal?.thus ? ['th'] : []),
                  ...(cal?.fri ? ['fr'] : []),
                  ...(cal?.sat ? ['sa'] : []),
                ],
                until: moment(cal?.time_off_end_date).format(),
                dtstart: moment(cal?.time_off_start_date).format(),
              },
              overlap: true,
              isDisabled: true,
              duration,
              timeOffRequestId: cal?.user_time_off_request_id,
            };
            if (cal?.status_id === 88 && !timeoffApprovedEventsLocal[key]) {
              const timeoffApprovedEventsColor =
                EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
              timeoffApprovedEventsAvailability = {
                ...availabilityUpdate,
                backgroundColor: 'rgb(161, 161, 161, 0.4)',
                textColor: timeoffApprovedEventsColor.textColor,
              };
              timeoffApprovedEventsLocal[key] =
                timeoffApprovedEventsAvailability;
            }
            if (cal?.status_id === 86 && !timeoffTentativeEventsLocal[key]) {
              const timeoffApprovedEventsColor =
                EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
              timeoffTentativeEvents = {
                ...availabilityUpdate,
                background:
                  'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
                textColor: timeoffApprovedEventsColor.textColor,
                // classNames: [classes.tentativeTimeOff],
              };
              timeoffTentativeEventsLocal[key] = timeoffTentativeEvents;
            }
          }
          if (
            cal?.time_off_start_date &&
            cal?.time_off_end_date &&
            cal?.is_blocked_time === 1 &&
            !blockedEventsLocal[key]
          ) {
            const blockedEventsColor = EVENT_COLORS[4];
            blockedEvents = {
              title: cal?.time_off_note || '',
              resourceId: cal?.installer_id,
              backgroundColor: blockedEventsColor?.bgColor,
              textColor: blockedEventsColor?.textColor,
              start: cal?.time_off_start_date
                ? moment(cal?.time_off_start_date).format()
                : '',
              end: cal?.time_off_end_date
                ? moment(cal?.time_off_end_date).format()
                : '',
              allDay: cal?.full_day === 1 ? true : false,
              eventType: EVENT_TYPES.BLOCK,
              overlap: true,
              isDisabled: true,
              installerId: cal?.installer_id,
              timeOffRequestId: cal?.user_time_off_request_id,
              editable: false,
              durationEditable: false,
            };
            blockedEventsLocal[key] = blockedEvents;
          }
          let eventDataLocalEventColor = {
            bgColor: EVENT_COLORS[1]?.bgColor,
            textColor: EVENT_COLORS[1]?.textColor,
          };
          if (
            view !== PROJECT_REPORT.FULL_CALENDAR &&
            cal.project_type &&
            !!DEFAULT_COLORS_BY_PROJECT_TYPE
          ) {
            const mappedColor = mapProjectTypeToColor(
              DEFAULT_COLORS_BY_PROJECT_TYPE,
              cal?.project_type,
              cal?.project_status,
              cal?.color_code
            );

            eventDataLocalEventColor = {
              bgColor: mappedColor?.bgColor,
              textColor: mappedColor?.textColor,
            };
          } else if (
            view === PROJECT_REPORT.FULL_CALENDAR &&
            cal?.project_type &&
            cal?.installer_id
          ) {
            const generatedColor = eventColorGenerator(cal?.installer_id);

            eventDataLocalEventColor = {
              bgColor: generatedColor,
              textColor: '#21272A',
            };
          }

          const isTentativeTimeOff =
            timeoffTentativeEvents?.eventType === 'timeoff';
          const isApprovedEventsAvailabilityTimeOff =
            timeoffApprovedEventsAvailability?.eventType === 'timeoff';
          const isBlockedEvents = blockedEvents?.eventType === 'blockedTime';

          let StartTime = cal?.schedule_date ? cal?.schedule_date : null;
          let EndTime = cal?.schedule_end_date ? cal?.schedule_end_date : null;
          const eventLocalData = {
            Id: cal?.project_id,
            recordId: index,
            EndTime,
            StartTime,
            ResourceId: cal?.installer_id,
            IsAllDay: cal?.full_day === 1 ? true : false,
            eventData: {
              installer_name: cal?.installer_name,
              id: cal?.project_id,
              title: cal?.project_desc || '',
              resourceId: cal?.installer_id,
              backgroundColor: eventDataLocalEventColor?.bgColor,
              textColor: eventDataLocalEventColor?.textColor,
              // backgroundColor:
              //   view !== PROJECT_REPORT.FULL_CALENDAR
              //     ? eventDataLocalEventColor.bgColor
              //     : `${eventDataLocalEventColor}`,
              // textColor:
              //   view !== PROJECT_REPORT.FULL_CALENDAR
              //     ? eventDataLocalEventColor.textColor
              //     : `${eventDataLocalEventColor}`,

              border: 'none',
              installer_id: cal?.installer_id,
              project_id: cal?.project_id,
              start: cal?.schedule_date,
              end: cal?.schedule_end_date,
              project_desc: cal?.project_desc,
              store_number: cal?.store_number,
              project_type: cal?.project_type,
              source_system_id: cal?.source_system_id,
              category: cal?.category,
              project_status: cal?.project_status,
              project_number: cal?.project_number,
              client_name: cal?.client_name,
              Time:
                moment(cal?.schedule_date).format('hh:mm A') +
                ' - ' +
                moment(cal?.schedule_end_date).format('hh:mm A'),
              mobile_phone: cal?.mobile_phone,
              address: cal?.address,
              timeoff_status: cal?.status_id,
              time_off_start_date: cal?.time_off_start_date,
              time_off_end_date: cal?.time_off_end_date,
              total_sale_amount: cal?.total_sale_amount,
              total_revenue: cal?.total_revenue,
              confirmation_status: cal?.confirmation_status,
              is_customer_requested: cal?.is_customer_requested,
              overlap: true,
              background:
                'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',

              schedule_date: cal?.schedule_date,
              schedule_end_date: cal?.schedule_end_date,
              installer_profile_image_url: cal?.installer_profile_image_url,
              basic_labor_sum: cal?.basic_labor_sum,
            },
          };
          ++index;
          if (StartTime && EndTime) {
            splitAndAddEvents(
              StartTime,
              EndTime,
              eventLocalData,
              eventDataLocal
            );
          }
          if (isTentativeTimeOff) {
            StartTime = timeoffTentativeEvents?.start
              ? timeoffTentativeEvents?.start
              : null;
            EndTime = timeoffTentativeEvents?.end
              ? timeoffTentativeEvents?.end
              : null;
            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                StartTime,
                EndTime,
                isTentativeTimeOff,
                eventData: timeoffTentativeEvents,
              },
              eventDataLocal
            );
          }
          if (isApprovedEventsAvailabilityTimeOff) {
            StartTime = timeoffApprovedEventsAvailability?.start
              ? timeoffApprovedEventsAvailability?.start
              : null;
            EndTime = timeoffApprovedEventsAvailability?.end
              ? timeoffApprovedEventsAvailability?.end
              : null;
            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                StartTime,
                EndTime,
                isApprovedEventsAvailabilityTimeOff,
                eventData: timeoffApprovedEventsAvailability,
              },
              eventDataLocal
            );
          }
          if (isBlockedEvents) {
            StartTime = blockedEvents?.start ? blockedEvents?.start : null;
            EndTime = blockedEvents?.end ? blockedEvents?.end : null;
            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                StartTime,
                EndTime,
                isBlockedEvents,
                eventData: blockedEvents,
              },
              eventDataLocal
            );
          }
          if (view !== PROJECT_REPORT.FULL_CALENDAR && cal?.installer_name) {
            const resourceValue = {
              id: cal.installer_id,
              is_customer_requested: cal.is_customer_requested,
              title: cal?.installer_name?.trim(),
              email: cal?.installer_email,
              phone: cal?.installer_phone,
              companyName: cal?.companyname,
              avatarIcon: cal?.installer_profile_image_url,
              installerbio: cal?.installerbio,
            };
            if (!resourceDataLocal[resourceValue.title]) {
              resourceDataLocal[resourceValue.title] = [resourceValue];
            }
          }
        }
        tempCalendarData = {
          eventData: eventDataLocal,
        };

        if (view !== PROJECT_REPORT.FULL_CALENDAR) {
          tempCalendarData.resourceData = Object.values(resourceDataLocal)
            .flat()
            .sort((a, b) => a.title.localeCompare(b.title));
        }
      }
    } catch (error) {
      console.log(error, '...error callGetCalendarDataApi');
    } finally {
      doLoadResource &&
        setCalendarData({
          ...calendarData,
          ...tempCalendarData,
          calendarBlock: false,
        });
    }
    console.log(new Date());
    console.log('ENDinggg', tempCalendarData);
    return tempCalendarData;
  };
  useEffect(() => {
    if (loader?.calendarLoader && filter?.calendarFilter) {
      if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        setCalendarData({
          ...calendarData,
          eventData: [],
          resourceData: [],
        });
        handleSetLoader({ calendarLoader: false });
      } else {
        callGetCalendarDataApi(true).then(() => {
          handleSetLoader({ calendarLoader: false });
        });
      }
    }
  }, [filter?.calendarFilter, view]);

  useEffect(() => {
    if (view === PROJECT_REPORT.FULL_CALENDAR) {
      handleSetLoader({
        calendarLoader: true,
      });
      handleSetFilter({
        calendarFilter: {
          ...calendarData.calendarFilter,
          installerIds:
            Array.isArray(selectedInstallers) && selectedInstallers.length
              ? selectedInstallers
                  .map(({ installerUserId }) => installerUserId)
                  .join(',')
              : undefined,
        },
      });
    }
  }, [selectedInstallers, isWeekendsVisible]);

  const renderBlockTimeTooltip = eventInfo => {
    return <div>{eventInfo?.title}</div>;
  };
  const renderTooltipContent = (eventInfo, time) => {
    return (
      <>
        <table className="w-full vertical-align-top text-xs">
          {view == PROJECT_REPORT.FULL_CALENDAR && eventInfo?.installer_name ? (
            <tr>
              <td className="minWidth-130">
                <strong>Technician Name</strong>
              </td>
              <td>{eventInfo?.installer_name}</td>
            </tr>
          ) : null}
          {eventInfo?.basic_labor_sum ? (
            <tr>
              <td className="minWidth-130">
                <strong>Qty</strong>
              </td>
              <td>{eventInfo?.basic_labor_sum}</td>
            </tr>
          ) : null}
          {time ? (
            <tr>
              <td className="minWidth-130">
                <strong>Time</strong>
              </td>
              <td>{time}</td>
            </tr>
          ) : null}
          {eventInfo?.store_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Store #</strong>
              </td>
              <td>{eventInfo?.store_number}</td>
            </tr>
          ) : null}
          {eventInfo?.client_name ? (
            <tr>
              <td className="minWidth-130">
                <strong>Client Name</strong>
              </td>
              <td>{eventInfo?.client_name}</td>
            </tr>
          ) : null}
          {eventInfo?.category ? (
            <tr>
              <td className="minWidth-130">
                <strong>Category</strong>
              </td>
              <td className="word_break">{eventInfo?.category}</td>
            </tr>
          ) : null}
          {eventInfo?.address ? (
            <tr>
              <td className="minWidth-130 word_break">
                <strong>Address</strong>
              </td>
              <td>{eventInfo?.address}</td>
            </tr>
          ) : null}
          {eventInfo?.total_sale_amount &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Sale $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_sale_amount}`}</td>
              </tr>
            )}
          {eventInfo?.total_revenue &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Revenue $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_revenue}`} </td>
              </tr>
            )}
          {eventInfo?.project_desc ? (
            <tr>
              <td className="minWidth-130">
                <strong>Description</strong>
              </td>
              <td className="word_break">
                {ellipsisString(eventInfo?.project_desc, 45)}
              </td>
            </tr>
          ) : null}
          {eventInfo?.project_type ? (
            <tr>
              <td className="minWidth-130">
                <strong>Type</strong>
              </td>
              <td>{eventInfo?.project_type}</td>
            </tr>
          ) : null}
          {eventInfo?.project_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Status</strong>
              </td>
              <td>{eventInfo?.project_status}</td>
            </tr>
          ) : null}
          {eventInfo?.project_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Project No</strong>
              </td>
              <td>{eventInfo?.project_number}</td>
            </tr>
          ) : null}
          {eventInfo?.mobile_phone ? (
            <tr>
              <td className="minWidth-130">
                <strong>Phone</strong>
              </td>
              <td>{eventInfo?.mobile_phone}</td>
            </tr>
          ) : null}
          {eventInfo?.confirmation_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Confirmation Status</strong>
              </td>
              <td>{eventInfo?.confirmation_status}</td>
            </tr>
          ) : null}
        </table>
      </>
    );
  };
  const timelineEventTemplate = props => {
    let localProp = props?.eventData || {};
    if (
      props?.isTentativeTimeOff ||
      props?.isApprovedEventsAvailabilityTimeOff ||
      props?.isBlockedEvents
    ) {
      return (
        <Tooltip
          title={renderBlockTimeTooltip(localProp)}
          arrow
          placement="top"
        >
          <div
            style={{
              background: props?.isBlockedEvents
                ? localProp?.backgroundColor
                : props?.isTentativeTimeOff
                  ? localProp?.background
                  : props?.isApprovedEventsAvailabilityTimeOff
                    ? localProp?.backgroundColor
                    : undefined,
              height: '100%',
            }}
            className="flex justify-content-center  align-items-center  flex-wrap w-12 text-800"
          >
            <div
              className={`event-${props?.recordId} w-12`}
              data-record-id={props?.recordId}
            >
              <div
                className="text-center p-m-2"
                style={{ whiteSpace: 'nowrap' }}
              >
                {localProp?.eventType !== 'timeoff' &&
                  localProp?.eventType !== 'blockedTime' &&
                  localProp?.timeText}
              </div>
              <div
                className="text-center p-m-2"
                style={{ whiteSpace: 'nowrap' }}
              >
                {localProp?.title || ''}
              </div>
            </div>
          </div>
        </Tooltip>
      );
    }

    return (
      <div
        className="grid w-full grid-nogutter h-full"
        style={{
          background: localProp?.backgroundColor,
          color: localProp?.textColor,
        }}
      >
        <div
          className={`w-12 p-1 event-${props?.recordId}`}
          data-record-id={props?.recordId}
        >
          <Tooltip
            title={renderTooltipContent(localProp, localProp?.Time)}
            arrow
            placement="right"
          >
            <i className="pi pi-exclamation-circle absolute right-0 mt-2 mr-3 z-5"></i>
          </Tooltip>
          <ProjectScheduleCard
            key={`ProjectScheduleCard_${props?.recordId}`}
            eventInfo={localProp}
            schedulerCardView={parentData?.schedulerCardView}
            cxSchedulerIcon={parentData?.cxSchedulerIcon}
            period={
              calendarRef
                ? getPeriod(calendarRef?.current?.currentView)
                : getPeriod(defaultView)
            }
            view={view}
          />
        </div>
      </div>
    );
  };
  const resourceTemplate = resourceInfo => {
    return (
      <>
        <div className="resource-container w-full">
          <div className="border-yellow"></div>
          <div className="border-blue"></div>
          <div className="card">
            <div className="info flex flex-column justify-content-start align-items-start flex-grow-1 pl-4 pt-4">
              <div className="name mb-2 ml-1 line-height-2">
                <div
                  className={`grid w-12 m-0 align-items-center relative scrollable-container ${resourceInfo?.resourceData?.installerbio ? 'cursor-pointer' : 'cursor-auto'}`}
                >
                  {resourceInfo?.resourceData?.installerbio && (
                    <>
                      <div className="installerbio-tooltip text-white absolute">
                        <div className="description">
                          {resourceInfo?.resourceData?.installerbio}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 md:col-6 lg:col-1 p-0 w-15rem">
                    {resourceInfo?.resourceData?.title}
                  </div>
                </div>
              </div>

              <div className="detail text-xs ml-1">
                <i className="pi pi-envelope text-xs mr-1" />
                <a
                  href={`mailto:${resourceInfo?.resourceData?.email}`}
                  className="text-color"
                >
                  {resourceInfo?.resourceData?.email}
                </a>
              </div>

              <div className="detail text-xs ml-1">
                <i className="pi pi-phone text-xs mr-1" />
                <a
                  href={`tel:${resourceInfo?.resourceData?.phone}`}
                  className="text-color"
                >
                  {resourceInfo?.resourceData?.phone}
                </a>
              </div>

              <div className="detail text-xs ml-1">
                {resourceInfo?.resourceData?.companyName && (
                  <>
                    <i className="pi pi-building text-xs mr-1" />
                    <strong>{resourceInfo?.resourceData?.companyName}</strong>
                  </>
                )}
              </div>
            </div>
            <div className="avatar pl-2 w-2 h-full pt-1 mt-2 mr-2">
              <TechnicianAvatar
                src={resourceInfo?.resourceData?.avatarIcon}
                alt={resourceInfo?.resourceData?.title}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const handleSelect = args => {
    if (!isOpen && args?.requestType === 'cellSelect') {
      const installerId = args?.data?.ResourceId;
      const { minutesDifference, startSlotTime } =
        selectedRapidSlotSyncFusion(args);
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.data?.StartTime,
            end: minutesDifference > 0 ? args?.data?.EndTime : startSlotTime,
          },
          initialInstaller: installerId,
        })
      );
    }
  };
  const handleEventClick = args => {
    args.cancel = true;
    if (args?.event?.eventData?.project_id) {
      window.open(
        `/project/view/${args?.event?.eventData?.project_id}`,
        '_blank'
      );
    }

    if (args?.event?.isBlockedEvents) {
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.event?.StartTime,
            end: args?.event?.EndTime,
            timeOffRequestId: args?.event?.eventData?.timeOffRequestId,
            note: args?.event?.eventData?.title,
          },
          initialInstaller: args?.event?.ResourceId,
          mode: 'edit',
        })
      );
    }
  };
  const [infoData, setInfoData] = useState();
  const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [dropModalValue, setDropModalValue] = useState(false);
  const [tableRowDropData, setTableRowDropData] = useState('');
  const [dropData, setDropData] = useState('');
  const [statusId, setStatusId] = useState(7);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartOrEndDateInvalid, setIsStartOrEndDateInvalid] = useState(false);
  const [schedulePercentage, setSchedulePercentage] = useState(
    parentData?.schedulePercentage
  );
  const [technicianAction, setTechnicianAction] = useState(
    parentData?.technicianAction
  );
  const toast = useRef(null);

  const continueScheduling = async () => {
    const projectData = infoData.rowData;
    const statusId =
      statusOptions &&
      statusOptions.length > 0 &&
      statusOptions?.find(item => item?.status === projectData?.project_status);

    let updateObj = {
      installerId: parseInt(infoData.resourceDetails.resourceData.id),
      projectId: parseInt(infoData.rowData.project_id),
      projectStartDateTime: moment(infoData.startTime),
      projectEndDateTime: moment(infoData.endTime),
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId?.status_id || '',
      user_time_off_request_ids: dropTimeOffRequestIds,
    };
    setButtonDisabled(true);
    await updateInstallerSchedule(updateObj);
    setButtonDisabled(false);
    setIsOpen(false);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const confirmScheduling = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This technician applied leave for this period. Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };
  const viewScheduleReport = projectId => {
    window.open(`/project/view/${projectId}`, '_blank');
  };
  const confirmBlockTimeWarning = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This slot is already blocked for the technician, Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };
  const handleDropModalClose = () => {
    setDropModalValue(false);
    setStartDate(null);
    setEndDate(null);
    setIsStartOrEndDateInvalid(false);
  };
  const saveDropCalendarData = async () => {
    let updateObj = {
      installerId: parseInt(tableRowDropData.resourceDetails.resourceData.id),
      projectId: parseInt(tableRowDropData.rowData.project_id),
      projectStartDateTime: startDate ? startDate?.toISOString() : undefined,
      projectEndDateTime: endDate ? endDate?.toISOString() : undefined,
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId,
      userAction: technicianAction,
      percentage:
        technicianAction === scheduleInfoUserCustomAction
          ? schedulePercentage
          : 0,
    };

    setButtonDisabled(true);
    const updateInstallerResponse = await updateInstallerSchedule(updateObj);
    if (updateInstallerResponse?.data?.status === 206) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: updateInstallerResponse?.message,
      });
    }
    setSchedulePercentage(0);
    setButtonDisabled(false);
    setIsOpen(false);
    // setReloadCalendar(!reloadCalendar);
    handleDropModalClose();
    calenderRefreshHandler();
  };
  const add1Hour = input => {
    const inputDate = new Date(input);
    inputDate.setHours(inputDate.getHours() + 1);
    return inputDate;
  };
  const getInfoDateEnd = date => {
    let infoDateEnd;
    if (calendarRef?.current?.currentView === 'TimelineDay') {
      infoDateEnd = add1Hour(date);
    }
    return infoDateEnd;
  };
  const handleExternalDrop = async event => {
    try {
      const scheduleObj = calendarRef.current;
      if (
        scheduleObj &&
        // tableRef.current &&
        scheduleObj.element.contains(event.target)
      ) {
        const cellData = scheduleObj.getCellDetails(event.target);
        if (
          cellData &&
          typeof cellData === 'object' &&
          cellData.hasOwnProperty('groupIndex') &&
          typeof cellData.groupIndex === 'number'
        ) {
          const resourceDetails = scheduleObj.getResourcesByIndex(
            cellData.groupIndex
          );
          const startTime = new Date(cellData.startTime);
          const endTime = new Date(cellData.endTime);
          const IsAllDay = !!cellData?.IsAllDay;
          const dragData = JSON.parse(event.dataTransfer.getData('eventData'));
          let showWarningMessage = false;
          let showBlockTimeWarning = false;
          const eventList = calendarData.eventData.filter(
            ({
              isTentativeTimeOff = false,
              isApprovedEventsAvailabilityTimeOff = false,
            }) => isTentativeTimeOff || isApprovedEventsAvailabilityTimeOff
          );
          for (let i = 0; i < eventList.length; i++) {
            const timeoff = eventList[i];
            if (
              (timeoff.ResourceId == resourceDetails.resourceData.id &&
                (moment(startTime).isBetween(
                  moment(timeoff.StartTime),
                  moment(timeoff.EndTime)
                ) ||
                  moment(endTime).isBetween(
                    moment(timeoff.StartTime),
                    moment(timeoff.EndTime)
                  ))) ||
              moment(startTime).isSame(moment(timeoff.StartTime)) ||
              moment(startTime).isSame(moment(timeoff.EndTime)) ||
              moment(endTime).isSame(moment(timeoff.EndTime)) ||
              moment(endTime).isSame(moment(timeoff.EndTime))
            ) {
              if (timeoff?.eventData?.rrule) {
                if (
                  moment(startTime).isBetween(
                    moment(timeoff.eventData.rrule?.dtstart),
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(startTime).isSame(
                    moment(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  moment(startTime).isSame(
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(endTime).isBetween(
                    moment(timeoff.eventData.rrule?.dtstart),
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(endTime).isSame(
                    moment(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  moment(endTime).isSame(moment(timeoff.eventData.rrule?.until))
                ) {
                  if (
                    IsAllDay &&
                    (timeoff.eventData.rrule?.byweekday?.includes(
                      moment(startTime).format('dd').toLowerCase()
                    ) ||
                      timeoff.eventData.rrule?.byweekday?.includes(
                        moment(endTime).format('dd').toLowerCase()
                      ))
                  ) {
                    showWarningMessage = true;
                    break;
                  }
                  if (!IsAllDay) {
                    const newStartHour = moment(
                      timeoff.eventData.rrule.dtstart
                    ).get('hour');
                    const newStartMinute = moment(
                      timeoff.eventData.rrule.dtstart
                    ).get('minute');
                    const newEndHour = moment(
                      timeoff.eventData.rrule.until
                    ).get('hour');
                    const newEndMinute = moment(
                      timeoff.eventData.rrule.until
                    ).get('minute');
                    const newStartDate = moment(startTime).set({
                      hour: newStartHour,
                      minute: newStartMinute,
                    });
                    const newEndDate = moment(endTime).set({
                      hour: newEndHour,
                      minute: newEndMinute,
                    });
                    if (
                      (newStartDate < moment(startTime) &&
                        moment(endTime) < newEndDate) ||
                      (newStartDate < moment(startTime).add(1, 'hours') &&
                        moment(endTime).add(1, 'hours') < newEndDate)
                    ) {
                      showWarningMessage = true;
                      break;
                    }
                  }
                  if (IsAllDay && !timeoff.rrule?.byweekday.length) {
                    showWarningMessage = true;
                    break;
                  }
                }
              }
            }
          }
          const blockEventsOfDroppedInstaller = calendarData.eventData.filter(
            i =>
              i.isBlockedEvents &&
              i.ResourceId === parseInt(resourceDetails.resourceData.id)
          );
          const blockTimeRequests = [];
          for (const blockTimeEvent of blockEventsOfDroppedInstaller) {
            if (
              moment(startTime).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(endTime).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(getInfoDateEnd(startTime)).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(getInfoDateEnd(endTime)).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(startTime).isSame(
                moment(new Date(blockTimeEvent?.StartTime))
              ) ||
              moment(startTime).isSame(
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(endTime).isSame(
                moment(new Date(blockTimeEvent?.StartTime))
              ) ||
              moment(endTime).isSame(moment(new Date(blockTimeEvent?.EndTime)))
            ) {
              showBlockTimeWarning = true;
              blockTimeRequests.push(
                blockTimeEvent?.eventData?.timeOffRequestId
              );
            }
          }
          setDropTimeOffRequestIds(blockTimeRequests);
          console.log(
            showWarningMessage,
            showBlockTimeWarning,
            '...event.target handleExternalDrop 5'
          );
          if (showWarningMessage) {
            confirmScheduling({
              resourceDetails,
              rowData: dragData?.raw,
              startTime,
              endTime,
            });
          } else {
            if (showBlockTimeWarning) {
              confirmBlockTimeWarning({
                resourceDetails,
                rowData: dragData?.raw,
                startTime,
                endTime,
              });
            } else {
              setDropModalValue(true);
              setTableRowDropData({
                resourceDetails,
                rowData: dragData?.raw,
              });
              setStatusId(7);
              setDropData(dragData?.raw);
              const startDateObj = structuredClone(startTime);
              const endDateObj = structuredClone(endTime);
              switch (calendarRef?.current?.currentView) {
                case 'TimelineDay':
                  endDateObj.setMinutes(endDateObj.getMinutes() + 30);
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                case 'TimelineWeek':
                case 'TimelineMonth':
                  endDateObj.setDate(endDateObj.getDate());
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error, 'on drop');
    }
  };
  // Reschedule
  const [isOpen, setIsOpen] = useState(false);
  const [infoValue, setInfoValue] = useState('');
  const { setAlert } = useAlerts();

  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [startDateStr, setStartDateStr] = useState('');

  const [endDateStr, setEndDateStr] = useState('');

  const [dialogSettings, setDialogSettings] = useState({
    title: 'Reschedule Confirmation',

    button1Text: 'Skip',

    button2Text: 'Continue',

    showButton1: true,

    showButton2: true,
  });

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const notesFormik = useFormik({
    initialValues: {
      type: 6,

      note_text: '',

      follow_up_date: null,
    },

    validationSchema: NotesFormikSchema,

    onSubmit: async values => {
      setButtonDisabled(true);
      if (values?.note_text?.length > 0) {
        await addNotes(
          parseInt(infoValue?.currentDetails?.eventData?.project_id),

          values,

          () => {},

          setAlert,

          () => {},

          () => {}
        );
      }

      if (isUpdateSchedule) {
        const statusType = statusOptions?.find(
          item =>
            item?.status ===
            infoValue?.currentDetails?.eventData?.project_status
        );

        let updateObj = {
          installerId: parseInt(infoValue.currentDetails.ResourceId),
          projectId: parseInt(infoValue.currentDetails.eventData.project_id),
          projectStartDateTime: moment(infoValue.startDateTime),
          projectEndDateTime: moment(infoValue.endDateTime),
          old_installerId:
            infoValue.currentDetails.eventData.installer_id.toString(),
          old_startDateTime: moment(
            infoValue.currentDetails.eventData.schedule_date
          ),
          old_endDateTime: moment(
            infoValue.currentDetails.eventData.schedule_end_date
          ),
          status_id: statusType?.status_id || '',
        };
        try {
          await updateInstallerSchedule(updateObj);
        } catch (error) {
          console.log(error, '...error updateInstallerSchedule');
        } finally {
          setIsOpen(false);
          calenderRefreshHandler();
        }
      }
      setButtonDisabled(false);
      //setReloadList(false);
    },

    enableReinitialize: true,
  });
  const updateSchedule = info => {
    if (info?.currentDetails?.isBlockedEvents) {
      return;
    }
    setIsUpdateSchedule(true);

    setStartDateStr(info?.startDateTime);

    setEndDateStr(info?.endDateTime);

    setInfoValue(info);

    notesFormik.setValues({
      type: '',

      note_text: '',

      follow_up_date: null,
    });

    setDialogSettings(prevState => ({
      ...prevState,

      title: 'Reschedule Confirmation',

      showButton2: true,
    }));

    setIsOpen(true);

    notesFormik?.handleReset();
  };
  const handleActionBegin = args => {
    if (args.requestType === 'eventChange') {
      handleWithinCalendarEventDragAndDrop(args);
    } else if (args.requestType === 'toolbarItemRendering') {
      args.items.push(REFRESH_ICON);
      args.items.push(FULLSCREEN_ICON);
    } else if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      view === PROJECT_REPORT.FULL_CALENDAR
    ) {
      setCalendarData({
        ...calendarData,
        eventData: [],
        resourceData: [],
      });
    }
  };
  const handleActionComplete = async args => {
    const scheduleElement = document.getElementById(targetID);
    if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      !isNavigate
    ) {
      setIsNavigate(true);
      setDefaultView(calendarRef?.current?.currentView);
      if (
        (view === PROJECT_REPORT.FULL_CALENDAR &&
          filter?.calendarFilter?.installerIds) ||
        view != PROJECT_REPORT.FULL_CALENDAR
      ) {
        await callGetCalendarDataApi(true);
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        setCalendarData({
          ...calendarData,
          eventData: [],
          resourceData: [],
          calendarBlock: false,
        });
      }
      // setIsNavigate(false);
    } else if (args.requestType === 'toolBarItemRendered' && scheduleElement) {
      const refreshIconElement = scheduleElement.querySelector(
        '.refreshIconScheduler'
      );
      if (refreshIconElement)
        refreshIconElement.onclick = () => calenderRefreshHandler();

      const fullscreenIconElement = scheduleElement.querySelector(
        '.fullscreenIconScheduler'
      );
      if (fullscreenIconElement) {
        fullscreenIconElement.onclick = () => {
          const fullscreenIconElementLocal = scheduleElement.querySelector(
            '.fullscreenIconScheduler'
          );
          if (fullscreenIconElementLocal.classList) {
            if (
              fullscreenIconElementLocal.classList.contains(
                'md-fullscreen_exit'
              )
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen_exit');
              fullscreenIconElementLocal.classList.add('md-fullscreen');
              fullscreenIconElementLocal.setAttribute('title', 'Fullscreen');
              setCalendarFullScreen(false);
            } else if (
              fullscreenIconElementLocal.classList.contains('md-fullscreen')
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen');
              fullscreenIconElementLocal.classList.add('md-fullscreen_exit');
              fullscreenIconElementLocal.setAttribute(
                'title',
                'Exit Fullscreen'
              );
              setCalendarFullScreen(true);
            }
          }
        };
      }
    }
  };
  const handleWithinCalendarEventDragAndDrop = args => {
    //setReadOnly(!readOnly);
    updateSchedule({
      startDateTime: args.data.StartTime,
      endDateTime: args.data.EndTime,
      currentDetails: { ...args.data },
    });
  };
  // Reschedule
  const calenderRefreshHandler = () => {
    callGetCalendarDataApi(true).then(() => {
      handleSetLoader({ calendarLoader: false });
    });
    calendarRef.current.refreshEvents(true);
  };

  const handleScroll = async options => {
    const selectedIds = options.resourceData.map(val => val.id);

    if (selectedIds.length) {
      const ids = [];
      selectedIds.forEach(installerId => {
        if (filter.installerMap?.[installerId]?.user_id) {
          ids.push(filter.installerMap[installerId].user_id);
        }
      });
      if (ids.length || filter?.calendarFilter?.installerIds) {
        let parsedIds = '';
        if (filter?.calendarFilter?.installerIds) {
          parsedIds = filter?.calendarFilter?.installerIds;
        } else {
          parsedIds = ids.join(',');
        }
        await callGetCalendarDataApi(false, {
          installerIds: parsedIds,
        });
      }
    }
  };

  const eventSettings = {
    dataSource: calendarData.eventData,
    template: timelineEventTemplate,
    // enableIndicator: false,
  };
  const group = {
    enableCompactView: false,
    resources: ['Resources'],
  };
  return (
    <div className="mt-2 w-full min-w-700">
      {loader?.calendarLoader ? (
        <div className="flex justify-content-center w-12">
          <SchedulerSkeleton
            rows={[firstRowData, secondRowData, secondRowData]}
          />
        </div>
      ) : (
        <BlockUI blocked={calendarData?.calendarBlock || isNavigate}>
          <ScheduleComponent
            id={targetID}
            rowAutoHeight={true}
            ref={calendarRef}
            width="100%"
            height="650px"
            currentView={defaultView}
            eventSettings={eventSettings}
            eventDragArea=".content-wrapper"
            cssClass="virtual-scrolling"
            actionComplete={handleActionComplete}
            eventClick={handleEventClick}
            eventDoubleClick={args => (args.cancel = true)}
            enableLazyLoading={true}
            enableVirtualization={true}
            allowVirtualScrolling={true}
            onDrop={handleExternalDrop}
            onDragOver={event => event.preventDefault()}
            //dragStop={handleWithinCalendarEventDragAndDrop}
            showHeaderBar={true}
            actionBegin={handleActionBegin}
            readOnly={readOnly}
            select={handleSelect}
            cellClick={args => {
              args.cancel = true;
            }}
            virtualScrollStop={handleScroll}
            timezone={localStorage.getItem('client_timezone')}
            dataBound={() => setIsNavigate(false)}
          >
            {view === PROJECT_REPORT.FULL_CALENDAR ? (
              <ViewsDirective>
                <ViewDirective
                  option="Day"
                  // displayName="Days"
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
                <ViewDirective
                  option="Week"
                  // displayName="Weeks"
                  timeScale={DEFAULT_DISABLED_TIME_SCALE}
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
                <ViewDirective
                  option="Month"
                  // displayName="Months"
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
              </ViewsDirective>
            ) : (
              <ViewsDirective>
                <ViewDirective
                  option="TimelineDay"
                  displayName="Day"
                  allowVirtualScrolling={true}
                  enableLazyLoading={true}
                  group={group}
                  resourceHeaderTemplate={resourceTemplate}
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
                <ViewDirective
                  option="TimelineWeek"
                  displayName="Week"
                  allowVirtualScrolling={true}
                  enableLazyLoading={true}
                  timeScale={DEFAULT_DISABLED_TIME_SCALE}
                  group={group}
                  resourceHeaderTemplate={resourceTemplate}
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
                <ViewDirective
                  option="TimelineMonth"
                  displayName="Month"
                  allowVirtualScrolling={true}
                  enableLazyLoading={true}
                  group={group}
                  resourceHeaderTemplate={resourceTemplate}
                  showWeekend={isWeekendsVisible}
                  workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
                />
              </ViewsDirective>
            )}
            {view !== PROJECT_REPORT.FULL_CALENDAR ? (
              <ResourcesDirective>
                <ResourceDirective
                  field="ResourceId"
                  title="Technicians"
                  name="Resources"
                  // allowMultiple={true}
                  dataSource={calendarData?.resourceData}
                  textField="title"
                  idField="id"
                ></ResourceDirective>
              </ResourcesDirective>
            ) : null}
            <Inject
              services={
                view === PROJECT_REPORT.FULL_CALENDAR
                  ? [Day, Week, Month, DragAndDrop]
                  : [TimelineViews, TimelineMonth, TimelineYear, DragAndDrop]
              }
            />
          </ScheduleComponent>
          <Toast ref={toast} />
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={continueScheduling}
          />
          {dropModalValue ? (
            <Modal
              open={dropModalValue}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              onClose={handleDropModalClose}
              className={classes.modal}
            >
              <Grid
                container
                item
                direction="row"
                xs={3}
                justifyContent="flex-end"
                spacing={2}
                className={classes.boxWidth}
              >
                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Store #
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.store_number}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Client Name{' '}
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.client_name}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Category
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.category}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Address
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.installation_address}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Type
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.project_type}{' '}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="align-items-center"
                >
                  <Typography className={classes.detailLabelModal}>
                    Schedule Start Date
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <div className={classes.detail}>
                    <Calendar
                      id="startDate"
                      className="w-full"
                      value={startDate}
                      onChange={e => setStartDate(e.value)}
                      showTime
                      hourFormat="12"
                      appendTo="self"
                      stepMinute={15}
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="align-items-center"
                >
                  <Typography className={classes.detailLabelModal}>
                    Schedule End Date
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <div className={classes.detail}>
                    <Calendar
                      id="endDate"
                      className="w-full"
                      value={endDate}
                      onChange={e => setEndDate(e.value)}
                      showTime
                      hourFormat="12"
                      appendTo="self"
                      stepMinute={15}
                    />
                    {isStartOrEndDateInvalid && (
                      <div className="mt-1 text-red-600">
                        End date must be after start date
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Status
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Autocomplete
                    id="status"
                    disableListWrap
                    options={statusOptions}
                    getOptionLabel={option => option && option.status}
                    onChange={(event, value) => {
                      setStatusId(value?.status_id);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    classes={{ input: classes.statusInput }}
                    value={statusOptions.find(
                      status => status.status_id === statusId
                    )}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="grid"
                >
                  <Typography className="col-3 pl-0">
                    Default Technician Action
                  </Typography>
                  <Typography className="col-1 text-center">:</Typography>
                  <Typography className="col-8">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={technicianAction}
                      name="radio-buttons-group"
                      onChange={e => setTechnicianAction(e?.target.value)}
                    >
                      <FormControlLabel
                        value={scheduleInfoUserWipeAction}
                        control={<Radio />}
                        label="Full Allocation"
                      />
                      <div className="flex align-items-center">
                        <FormControlLabel
                          value={scheduleInfoUserCustomAction}
                          control={<Radio />}
                          label="Custom Allocation"
                        />
                        {technicianAction === scheduleInfoUserCustomAction && (
                          <PFInputNumber
                            id="allocate_percentage"
                            value={schedulePercentage}
                            onChange={(event, value) => {
                              const percentage = getPercentageValue(value);
                              setSchedulePercentage(percentage);
                            }}
                            suffix={'%'}
                            placeholder="%"
                            className="w-36"
                            pt={{
                              root: {
                                className: 'h-2rem pb-2 pt-1',
                              },
                              input: {
                                root: {
                                  className:
                                    'w-full border-noround border-bottom-1 border-top-none border-x-none p-0  shadow-none border-gray-500',
                                },
                              },
                            }}
                            min={0}
                            maxLength={6}
                            max={100}
                          />
                        )}
                      </div>

                      <FormControlLabel
                        value={scheduleInfoUserFillAction}
                        control={<Radio />}
                        label="Allocate Remaining"
                      />

                      <FormControlLabel
                        value={scheduleInfoUserSkipAction}
                        control={<Radio />}
                        label="Leave Unassigned"
                      />
                    </RadioGroup>
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Source Status
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.IMS_status}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Project No
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.project_number}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Mobile Number
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.home_phone}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Alternate Number
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.mobile_phone}{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => viewScheduleReport(dropData?.project_id)}
                  >
                    GoTo Job
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveDropCalendarData}
                    disabled={isStartOrEndDateInvalid}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDropModalClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Modal>
          ) : null}

          <InstallerViewBlockCalendar
            calenderRefreshHandler={calenderRefreshHandler}
            setIsBlockTime={() => {}}
          />
          <form>
            <GenericDialog
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
                notesFormik.handleReset();
                setButtonDisabled(false);
                calenderRefreshHandler();
              }}
              disabledButton2={buttonDisabled}
              disabledButton1={buttonDisabled}
              dialogSettings={dialogSettings}
              handleSave={() => notesFormik.handleSubmit()}
              button2Text={buttonDisabled ? 'Updating...' : null}
            >
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <DialogContentText>
                    <b>
                      Please confirm, if you would like to reschedule this job
                      for {moment(startDateStr).format('YYYY-MM-DD hh:mm A')} -{' '}
                      {moment(endDateStr).format('YYYY-MM-DD hh:mm A')}
                    </b>
                  </DialogContentText>
                </Grid>
                <Grid item>
                  <TextField
                    id="note_text"
                    name="note_text"
                    label="Note"
                    multiline
                    disabled={buttonDisabled}
                    onChange={notesFormik.handleChange}
                    onBlur={notesFormik.handleBlur}
                    value={notesFormik?.values?.note_text}
                  />
                </Grid>
              </Grid>
            </GenericDialog>
          </form>
        </BlockUI>
      )}
    </div>
  );
};
export default SchedulerCalendar;
