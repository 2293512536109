import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PFDialog from '../../shared/PFPrime/PFDialog';
import PFButton from '../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import { setFilterTechinicianView } from '../../../redux/slices/scheduler-technician-view.slice';

const TechnicianFilter = ({
  handleClosePopup,
  showTechnicianFilter,

  handleResetFilters,
  installerOptions,

  workRoomOptions,
  setSearchWorkroomText,

  storeLoader,
  workRoomLoader,
  userTypeLoader,
  calendarLoading,
  handleSetFilter,
  filter,
}) => {
  const dispatch = useDispatch();
  const { technicians } = useSelector(state => state.technicians);
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { districts } = useSelector(state => state.districts);
  const { userTypes } = useSelector(state => state.userTypes);
  const { workrooms } = useSelector(state => state.workrooms);
  const { isDailogOpen } = useSelector(state => state.schedulerTechnicianView);
  const debouncedSearchWorkroom = debounce(value => {
    setSearchWorkroomText(value);
  }, 300);
  // const debouncedSearchStore = debounce(value => {
  //   setSearchStoreText(value);
  // }, 300);
  const installerFormik = useFormik({
    initialValues: {
      filterTechnicial: '',
      filterProjectType: '',
      filterStore: '',
      filterCategory: '',
      filterDistrict: '',
      filterUserType: '',
      filterWorkroom: '',
    },
    onSubmit: values => {
      const user_id =
        values?.filterTechnicial?.length > 0
          ? values?.filterTechnicial?.map(val => val?.user_id).join(',')
          : null;

      if (user_id?.length > 0) {
        const calendarFilter = { ...filter?.previousData };
        if (values?.filterTechnicial?.length !== 10) {
          calendarFilter.installerIds = user_id;
        }

        handleSetFilter({
          calendarFilter,
        });
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );
      } else if (
        (Array.isArray(values?.filterCategory) &&
          values?.filterCategory?.length > 0) ||
        (Array.isArray(values?.filterStore) &&
          values?.filterStore?.length > 0) ||
        (Array.isArray(values?.filterProjectType) &&
          values?.filterProjectType?.length > 0) ||
        (Array.isArray(values?.filterDistrict) &&
          values?.filterDistrict?.length > 0) ||
        (Array.isArray(values?.filterUserType) &&
          values?.filterUserType?.length > 0) ||
        (Array.isArray(values?.filterWorkroom) &&
          values?.filterWorkroom?.length > 0)
      ) {
        const projectTypeId =
          values?.filterProjectType?.length > 0
            ? values?.filterProjectType
                ?.map(val => val?.project_type_id)
                .join(',')
            : '';

        const projectCategoryId =
          values?.filterCategory && values?.filterCategory?.length > 0
            ? values?.filterCategory
                ?.map(val => val?.project_category_id)
                ?.join(',')
            : '';

        const districts =
          values?.filterDistrict?.length > 0
            ? values?.filterDistrict?.filter(item => !!item)?.join(',')
            : '';

        const storeId =
          values?.filterStore?.length > 0
            ? values?.filterStore?.map(val => val?.store_id)?.join(',')
            : '';

        const userTypeId =
          values?.filterUserType.length > 0
            ? values?.filterUserType
                .filter(item => !!item)
                ?.map(item => item.user_type_id)
                ?.join(',')
            : [];

        const selectedWorkroomView =
          values?.filterWorkroom?.length > 0
            ? values?.filterWorkroom
                .filter(workRoom => workRoom?.type_id)
                .map(workRoom => workRoom?.type_id)
                .join(',')
            : '';

        const calendarFilter = {
          projectCategoryId: projectCategoryId
            ? projectCategoryId
            : filter?.previousData?.projectCategoryId,
          projectStoreId: storeId
            ? storeId
            : filter?.previousData?.projectStoreId,
          projectTypeIds: projectTypeId
            ? projectTypeId
            : filter?.previousData?.projectTypeIds,
          workRoomIds: selectedWorkroomView,
          districts: districts,
          userTypeIds: userTypeId,
        };
        handleSetFilter({
          calendarFilter,
        });
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );

        setIsFiltersSelected(true);
      }
    },
  });
  const handleReset = () => {
    handleResetFilters();
    installerFormik.handleReset();
    dispatch(
      setFilterTechinicianView({
        isDailogOpen: false,
      })
    );
  };
  return (
    <PFDialog
      header="Filter Technician"
      show={isDailogOpen}
      className="w-11 lg:w-8"
      hide={() => {
        handleClosePopup();
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );
      }}
      draggable={false}
      pt={{
        headertitle: {
          className: 'text-2xl',
        },
      }}
      BodyComponent={
        <>
          <form
            className="w-full"
            onSubmit={event => {
              event.preventDefault();
              installerFormik.handleSubmit();
            }}
          >
            <div className="grid overflow-y-auto">
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={installerFormik?.values?.filterTechnicial || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterTechnicial', value);
                    }}
                    options={technicians || []}
                    optionLabel="full_name"
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={true}
                    selectedItemsLabel={
                      installerFormik?.values?.filterTechnicial.length <
                      technicians.length
                        ? `${installerFormik?.values?.filterTechnicial.length} Selected`
                        : 'All Selected'
                    }
                    disabled={
                      installerFormik?.values?.filterCategory?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0 ||
                      installerFormik?.values?.filterProjectType?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0 ||
                      installerFormik?.values?.filterUserType?.length > 0
                        ? true
                        : false
                    }
                    maxSelectedLabels={2}
                    placeholder="Select Technicians"
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="technician">Technician</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={installerFormik?.values?.filterProjectType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterProjectType', value);
                    }}
                    options={types || []}
                    optionLabel="project_type"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="projectType">Type</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="store"
                    className="w-full"
                    value={installerFormik.values?.filterStore || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterStore', value);
                    }}
                    options={
                      Array.isArray(projectStores)
                        ? projectStores?.map(store => ({
                            ...store,
                            label: `${store.store_number} - ${store.store_name}`,
                          }))
                        : []
                    }
                    optionLabel="label"
                    maxSelectedLabels={1}
                    filter
                    filterPlaceholder="Type to search..."
                    // onFilter={e => {
                    //   debouncedSearchStore(e?.filter);
                    // }}
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    appendTo={null}
                    // loading={storeLoader}
                  />
                  <label htmlFor="store">Store</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="category"
                    className="w-full"
                    value={installerFormik?.values?.filterCategory || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterCategory', value);
                    }}
                    options={categories || []}
                    optionLabel="category"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      panel: {
                        className: 'max-w-26rem',
                      },
                    }}
                    appendTo={null}
                  />
                  <label htmlFor="category">Category</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="district"
                    className="w-full"
                    value={installerFormik?.values?.filterDistrict || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterDistrict', value);
                    }}
                    options={districts || []}
                    optionLabel="district"
                    optionValue="district"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="district">District</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="userType"
                    className="w-full"
                    value={installerFormik?.values?.filterUserType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterUserType', value);
                    }}
                    options={userTypes || []}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="userType">User Type</label>
                </FloatLabel>
              </div>
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="workroom"
                    className="w-full"
                    value={installerFormik?.values?.filterWorkroom || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterWorkroom', value);
                    }}
                    options={workrooms || []}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    onFilter={e => {
                      debouncedSearchWorkroom(e?.filter);
                    }}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    loading={workRoomLoader}
                  />
                  <label htmlFor="workroom">Workroom</label>
                </FloatLabel>
              </div>
            </div>
            <div className="w-12 flex justify-content-end mt-4">
              <PFButton
                outlined
                label="Filter Reset"
                icon={`${calendarLoading ? 'pi pi-spin pi-spinner' : null}`}
                onClick={() => handleReset()}
                className="pr-4"
              />
              <PFButton
                primary
                type="submit"
                label="Apply Filters"
                className="ml-2"
              />
            </div>
          </form>
        </>
      }
    ></PFDialog>
  );
};
export default TechnicianFilter;
