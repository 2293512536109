import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const getReportData = async (reportId, query) => {
  try {
    const queryParams = {
      params: {},
    };
    if (query && typeof query === 'object' && !Array.isArray(query)) {
      for (const key in query) {
        if (Boolean(query[key]) || query[key] === 0) {
          queryParams.params[key] = Array.isArray(query[key]) ? query[key].join(",") : query[key];
        }
      }
    }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/reports/${reportId}`,
      queryParams
    );
    return response?.data;
  } catch (error) {
    return null;
  }
};
