import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import {
  DEFAULT_COLORS_BY_PROJECT_TYPE,
  TEXT_COLOR_BLACK,
} from '../../../../constants';
import { EVENT_COLORS } from '../../constants/constants';

export const getCalendarData = async params => {
  const queryParam = { params: {} };
  for (const key in params) {
    if (Boolean(params[key]) || params[key] === 0) {
      queryParam.params[key] = params[key];
    }
  }
  if (
    !('isJobCoverage' in queryParam.params) ||
    !queryParam.params.isJobCoverage
  ) {
    queryParam.params.isJobCoverage = true;
  }
  return await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view`,
    queryParam
  );
};
export const updateInstallerSchedule = async updateObj => {
  return await axios.post(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/update`,
    updateObj
  );
};
export const mapProjectTypeToColor = (
  colorsByProjectType,
  projectType,
  projectBusted,
  color_code = ''
) => {
  if (projectBusted === 'Busted') {
    return {
      bgColor: DEFAULT_COLORS_BY_PROJECT_TYPE?.Busted,
    };
  } else {
    if (color_code) {
      return {
        bgColor: color_code,

        textColor: TEXT_COLOR_BLACK,
      };
    }
    if (
      Object.prototype.hasOwnProperty.call(colorsByProjectType, projectType)
    ) {
      return {
        bgColor: colorsByProjectType[projectType],

        textColor: TEXT_COLOR_BLACK,
      };
    }

    return EVENT_COLORS[1]; // default
  }
};

export const getAllInstallersList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
