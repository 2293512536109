import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';

import SchedulerCalendar from './calendar-2';
import InstallersListFilter from './installers-list-filter';

const NewScheduler = ({
  tableRef,
  calendarRef,
  targetID,
  filter,
  data,
  loader,
  handleSetFilter,
  handleSetData,
  handleSetLoader,
  statusOptions,
  setCalendarFullScreen,
  calendarFullScreen,
  view,
}) => {
  const dispatch = useDispatch();
  const { isWeekendsVisible, isSelectAllInstallers } = useSelector(
    state => state.newScheduler
  );
  const { technicians } = useSelector(state => state.technicians);

  const handleWeekendToggle = () => {
    dispatch(
      setNewSchedulerComponent({
        isWeekendsVisible: !isWeekendsVisible,
      })
    );
  };
  const handleAllInstallerToggle = event => {
    const isSelectAll = event?.target?.checked;
    const installerIds = [];
    const selectedInstallersList = isSelectAll
      ? Array.isArray(technicians)
        ? technicians?.map(item => {
            installerIds.push(item?.user_id);
            return {
              name: `${item?.first_name} ${item?.last_name}`,
              id: item?.installer_id,
              installerUserId: item?.user_id,
            };
          })
        : []
      : [];
    dispatch(
      setNewSchedulerComponent({
        selectedInstallers: selectedInstallersList,
        isSelectAllInstallers: isSelectAll,
      })
    );
  };

  return (
    <>
      <div className="flex flex-column w-full">
        <InstallersListFilter />
        <div className="flex w-full gap-4 mb-2">
          <div className="flex mt-2">
            <Checkbox
              inputId="weekends"
              name="weekends"
              value="weekends"
              onChange={handleWeekendToggle}
              checked={isWeekendsVisible}
            />
            <label htmlFor="weekends" className="ml-2">
              Show Weekends
            </label>
          </div>
          <div className="flex  mt-2">
            <Checkbox
              inputId="isSelectAllInstallers"
              name="isSelectAllInstallers"
              value="isSelectAllInstallers"
              onChange={handleAllInstallerToggle}
              checked={isSelectAllInstallers}
            />
            <label htmlFor="isSelectAllInstallers" className="ml-2">
              Select All
            </label>
          </div>
        </div>
        <div className="flex w-full">
          <SchedulerCalendar
            tableRef={tableRef}
            calendarRef={calendarRef}
            targetID={targetID}
            filter={filter}
            data={data}
            loader={loader}
            handleSetFilter={handleSetFilter}
            handleSetData={handleSetData}
            handleSetLoader={handleSetLoader}
            statusOptions={statusOptions}
            setCalendarFullScreen={setCalendarFullScreen}
            calendarFullScreen={calendarFullScreen}
            view={view}
          />
        </div>
      </div>
    </>
  );
};

export default NewScheduler;
