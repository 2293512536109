import React, { useEffect, useState, useRef, useContext } from 'react';
import { Toast } from 'primereact/toast';

import PFButton from '../../../shared/PFPrime/PFButton';
import { ShippingContext } from '../ShippingContainer';
import { packageReceivedGetService } from '../services/shipping.service';

export const ShippingHeader = () => {
  const toast = useRef(null);
  const previousValuesRef = useRef({});
  const { shippingContextValue, setShippingContextValue } =
    useContext(ShippingContext);

  const projectShippingGetApi = async () => {
    const res = await packageReceivedGetService(
      shippingContextValue?.project_id
    );
    if (res?.status) {
      res?.data?.project_shipping_id &&
        setShippingContextValue(preValue => ({
          ...preValue,
          project_shipping_id: res?.data?.project_shipping_id || null,
          shippingData: res?.data,
        }));

      previousValuesRef.current = {
        expected_package: res?.data?.expected_package || null,
      };
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Internal server error',
      });
    }
  };

  useEffect(() => {
    if (shippingContextValue?.deleteFlag) {
      projectShippingGetApi().then(() => {
        setShippingContextValue(preValue => ({
          ...preValue,
          deleteFlag: false,
        }));
      });
    }
  }, [shippingContextValue?.deleteFlag]);

  useEffect(() => {
    shippingContextValue?.project_id && projectShippingGetApi();
  }, [shippingContextValue?.project_id]);

  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6">
          <h3 className="text-lg">Shipping</h3>
        </div>
        <div className="col-12 md:col-12 lg:col-6">
          <PFButton
            label="Add New Shipment"
            icon="pi pi-plus"
            iconPos="left"
            onClick={() => {
              shippingContextValue?.setVisibleMode({
                visible: 'add',
                data: {},
              });
              shippingContextValue?.hide();
            }}
            style={{ float: 'right' }}
          />
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3 mr-2">
          <span className="p-float-label flex-1 flex align-items-center">
            <span className="text-primary">
              Total # of Packages Expected :&nbsp;
            </span>
            <span className="font-bold">
              {shippingContextValue?.expected_package || 0}
            </span>
          </span>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <span className="p-float-label flex-1 flex align-items-center">
            <span className="text-primary">
              Total # of Packages Received :&nbsp;
            </span>
            <span className="font-bold">
              {shippingContextValue?.received_package || 0}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};
