// **** React Imports ****
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// **** External Utilities ****
import {
  Avatar,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Backdrop,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import _ from 'lodash';

// **** Custom Components ****

// **** Services *****
// **** Services *****

// **** Styles *****
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { getSystemTenants } from '../../Admin/Configuration/RestrictAccessbyRole.service';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { getRelatedPOData } from '../RelatedPO/RelatedPO.service';
import {
  checkPermission,
  getUrlExtension,
  downloadFile,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import useToken from '../../../hooks/useToken';
import PFButton from '../../shared/PFPrime/PFButton';
import {
  DocFileTypeError,
  DOCUMENT_PHOTO_TYPE,
  PROJECT_TYPES,
  SOURCE_SYSTEMS,
} from '../../../constants';

import { useStyles } from './Docs.styles.js';
import {
  getListOfDocuments,
  getDocumentTypes,
  upLoadDocumentOrPhoto,
  applyInstallerVisibilityDocuments,
  getRelatedDocuments,
  updateDocumentById,
  getFromSource,
  copyRelatedDocuments,
  deleteProjectDocument,
  bulkUpdateService,
} from './Docs.service';
import ProjectMaterials from './project-materials.jsx';
import UploadStoreTypeDialog from './UploadStoreTypeDialog';
import ThumbnailCard from './ThumbnailCard';
import ProjectConfigDetails from './project-config-details.jsx';
import { UploadActionContext } from './context/context.jsx';

const Docs = ({ projectId, formik, setReloadForm }) => {
  const [documents, setDocuments] = React.useState();
  const docInputRef = React.useRef();
  const [documentError, setDocumentError] = React.useState(null);
  const [relateDocuments, setRelatedDocuments] = React.useState();
  const [tenantPermissionData, setTenantPermissionData] = React.useState([]);
  const [, setdocumentTypes] = useState([]);
  const [relatedPOResponse, setRelatedPOResponse] = React.useState({});
  const [loading, setLoading] = useState();
  const [relatedDocloading, setRelatedDocloading] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [docLoader, setDocLoader] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { setAlert, clearAlert } = useAlerts();
  const { accessToken } = useToken();
  const [installerVisibleDocuments, setInstallerVisibleDocuments] =
    React.useState([]);
  const [offlineDocuments, setOfflineDocuments] = React.useState([]);
  const [relatedOfflineDocuments, setRelatedOfflineDocuments] = React.useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState('');

  const [docsPayload, setDocsPayload] = React.useState([]);
  const [relatedDocsPayload, setRelatedDocsPayload] = React.useState([]);
  const [relatedProjectDocsOfflineMode, setRelatedProjectDocsOfflineMode] =
    React.useState(false);
  const [relatedSelectedProject, setRelatedSelectedProject] =
    React.useState(false);
  const [installerSelectAllState, setInstallerSelectAllState] =
    React.useState(false);
  const [offlineSelectAllState, setOfflineSelectAllState] =
    React.useState(false);
  const [installerVisible, setInstallerVisible] = React.useState(
    !checkPermission(permissions?.userManagement?.userManagementModifyInstaller)
  );
  const [reviewPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.viewDocumentReview)
  );

  const [addDocCategoryPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.addDocCategory)
  );

  const [editDocCategory] = React.useState(
    !checkPermission(permissions?.documentCenter?.editDocCategory)
  );

  const [selectedJob, setSelectedJob] = React.useState();
  const [documentVisibleToInstaller, setDocumentVisibleToInstaller] =
    React.useState(false);
  const [downloadDocumentLoading, setDownloadDocumentLoading] =
    React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [documentUrlValue, setDocumentUrlValue] = React.useState(null);
  const [documentNameValue, setDocumentNameValue] = React.useState(null);

  const [openDialogStoreType, setOpenDialogStoreType] = React.useState(false);
  const [storeType, setStoreType] = React.useState('NEEDS_REVIEW');
  const [hdDocCdNumber, setHdDocCdNumber] = React.useState(null);
  const [llDocType, setLlDocType] = React.useState(null);
  const [permissionCheckBox, setPermissionCheckBox] = React.useState([]);

  const [copyRelatedDoc, setCopyRelatedDoc] = useState({});
  const [relateDocumentsIds, setRelateDocumentsIds] = useState([]);

  const documentDocTypeId = DOCUMENT_PHOTO_TYPE?.DOCUMENT;

  const [uploadContextValue, setUploadContextValue] = useState({
    show: false,
    permissions: {
      installerVisible: installerVisible,
      reviewPermission: reviewPermission,
      addCategoryPermission: addDocCategoryPermission,
      editCategoryPermission: editDocCategory,
    },
    document_type_id: 1,
    onShow: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: true,
      }));
    },
    onHide: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: false,
      }));
      uploadContextValue?.resetContext();
    },
    data: {
      document_store_type: 'INTERNAL',
      is_installer_visibility: 0,
      is_proj_docu_visibility: 0,
      is_customer_visible: 0,
      reviewed_status: 0,
    },
    handleChange: data => {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: { ...preValue?.data, ...data },
      }));
    },
    resetContext: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        req_from: '',
        data: {
          document_store_type: 'INTERNAL',
          is_installer_visibility: 0,
          is_proj_docu_visibility: 0,
          is_customer_visible: 0,
          reviewed_status: 0,
        },
      }));
    },
  });
  const fetchTenantPermissions = async () => {
    const response = await getSystemTenants();
    if (response) {
      setTenantPermissionData(response?.tenant_permissions[0]);
    }
  };

  React.useEffect(() => {
    if (selectedJob?.project_id) {
      setCopyRelatedDoc({
        copyFrom: selectedJob?.project_id,
        copyTo: parseInt(projectId),
        copiedIds: [],
      });
    }
  }, [selectedJob]);

  React.useEffect(() => {
    fetchTenantPermissions();
  }, []);

  React.useEffect(() => {
    if (
      tenantPermissionData?.excluded_permissions?.includes(
        'INSTALLER_WITH_ALL_DOCUMENT'
      )
    ) {
      setDocumentVisibleToInstaller(false);
    } else if (
      tenantPermissionData?.included_permissions?.includes(
        'INSTALLER_WITH_ALL_DOCUMENT'
      )
    ) {
      setDocumentVisibleToInstaller(true);
    }
  }, [tenantPermissionData]);

  React.useEffect(() => {
    async function getListOfDocumentsData(projectId) {
      await getListOfDocuments(
        projectId,
        setDocuments,
        setLoading,
        documentDocTypeId
      );
    }
    getDocumentTypes(setdocumentTypes);
    getRelatedPOData(projectId, setLoading, setRelatedPOResponse);
    getListOfDocumentsData(projectId);
    if (selectedJob?.project_id) {
      handleJobSelection(selectedJob);
    }
  }, [reloadList]);

  React.useEffect(() => {
    setInstallerVisibleDocuments(
      documents
        ?.filter(doc => doc.documetTypeId === 1)
        ?.map(document => {
          return {
            documentId: document.documentId,
            isVisibleToInstaller: document.is_installer_visibility
              ? true
              : false,
          };
        }) || []
    );

    setPermissionCheckBox(
      documents
        ?.filter(doc => doc.documetTypeId === 1)
        .map(document => {
          return {
            documentId: document?.documentId,
            isCustomerProjectDoucmentVisible: document?.is_customer_visible
              ? true
              : false,
          };
        }) || []
    );

    if (
      documents
        ?.filter(doc => doc.documetTypeId === 1)
        ?.filter(doc => doc.is_installer_visibility === 1)?.length ==
      documents?.filter(doc => doc.documetTypeId === 1)?.length
    ) {
      setInstallerSelectAllState(true);
    } else {
      setInstallerSelectAllState(false);
    }
    setOfflineDocuments(
      documents
        ?.filter(doc => doc.documetTypeId === 1)
        ?.map(document => {
          return {
            documentId: document.documentId,
            isProjectDoucmentOffine: document.is_proj_docu_visibility
              ? true
              : false,
          };
        }) || []
    );
    if (
      documents
        ?.filter(doc => doc.documetTypeId === 1)
        ?.filter(doc => doc.is_proj_docu_visibility === 1)?.length ==
      documents?.filter(doc => doc.documetTypeId === 1)?.length
    ) {
      setOfflineSelectAllState(true);
    } else {
      setOfflineSelectAllState(false);
    }
  }, [documents]);

  React.useEffect(() => {
    const relateDocIds = [];
    setRelatedOfflineDocuments(
      relateDocuments
        ?.filter(doc => doc.documetTypeId === 1)
        ?.map(document => {
          relateDocIds.push(document?.documentId);
          return {
            documentId: document?.documentId,
            isRelatedProjectDoucmentOffine: document.is_rel_proj_docu_visibility
              ? true
              : false,
          };
        }) || []
    );
    setRelateDocumentsIds(relateDocIds);
  }, [relateDocuments, reloadList]);

  const handleFileDownload = async documentId => {
    let docData =
      documents?.filter(doc => doc.documentId === documentId)[0] || {};
    let docDownloadUrl =
      documents?.filter(doc => doc.documentId === documentId)[0].documentUrl ||
      '';
    if (docDownloadUrl) {
      downloadFile(
        docDownloadUrl,
        docData.documentName,
        setDownloadDocumentLoading
      );
    }
    /* const existingPdfBytes = await fetch(
      docData.documetTypeId === 2
        ? `${URL_CONSTANTS.API.BASE_URL}${docDownloadUrl}?token=${accessToken}`
        : docDownloadUrl
    ).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, `${docData.documentName}`, 'application/pdf'); */
  };

  const handleFileDownloadRelatedDocument = async documentId => {
    let docData =
      relateDocuments?.filter(doc => doc.documentId === documentId)[0] || {};
    let docDownloadUrl =
      relateDocuments?.filter(doc => doc.documentId === documentId)[0]
        .documentUrl || '';

    if (docDownloadUrl) {
      downloadFile(
        docDownloadUrl,
        docData.documentName,
        setDownloadDocumentLoading
      );
    }
  };

  const handleUpload = (files, documentTypeId) => {
    setReloadList(false);
    setLoading(false);
    setDocLoader(true);
    const uploadObj = {
      file: [],
      document_type_id: documentTypeId,
      document_name: files.name,
      ...uploadContextValue?.data,
    };
    Object.values(files).forEach(file => {
      uploadObj?.file?.push({ file: file, document_name: file?.name });
    });
    try {
      upLoadDocumentOrPhoto(
        projectId,
        uploadObj,
        setLoading,
        setAlert,
        setReloadList,
        setReloadForm,
        setDocLoader
      ).then(() => {
        uploadContextValue?.onHide();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleJobSelection = project => {
    getRelatedDocuments(
      project.project_id,
      setRelatedDocuments,
      setRelatedDocloading,
      documentDocTypeId
    );
    setSelectedJob(project);
  };

  const handleInstallerVisiblity = document_id => {
    let installerDocs = _.cloneDeep(installerVisibleDocuments);
    let documentsPayload = _.cloneDeep(docsPayload);

    const filteredDocs =
      installerDocs?.filter(doc => doc.documentId == document_id) || [];
    if (filteredDocs.length > 0) {
      filteredDocs[0].isVisibleToInstaller =
        !filteredDocs[0].isVisibleToInstaller;
    }

    //Checking if document in selected docs
    if (
      documentsPayload?.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      installerDocs?.filter(doc => doc.documentId == document_id).length > 0
    ) {
      documentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isVisibleToInstaller = !documentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isVisibleToInstaller;
    } else if (
      documentsPayload?.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      installerDocs?.filter(doc => doc.documentId != document_id).length > 0
    ) {
      documentsPayload.splice(
        documentsPayload.findIndex(doc => doc.documentId == document_id),
        1
      );
    } else {
      documentsPayload.push({
        documentId: document_id,
        isVisibleToInstaller:
          installerDocs?.filter(doc => doc.documentId == document_id)?.[0]
            ?.isVisibleToInstaller || false,
      });
    }

    installerDocs?.filter(doc => doc.isVisibleToInstaller === true).length ==
    documents?.filter(doc => doc.documetTypeId === 1).length
      ? setInstallerSelectAllState(true)
      : setInstallerSelectAllState(false);

    setDocsPayload(documentsPayload);
    setInstallerVisibleDocuments(installerDocs);
  };

  const [reviewDocs, setReviewDocs] = useState([]);
  const handleReviewAvailability = (documentId, flag) => {
    setReviewDocs(prevReviewDocs => {
      const existingDocIndex = prevReviewDocs.findIndex(
        doc => doc.documentId === documentId
      );

      if (existingDocIndex !== -1) {
        const updatedDocs = [...prevReviewDocs];
        updatedDocs[existingDocIndex].reviewed_status = flag;
        return updatedDocs;
      } else {
        return [
          ...prevReviewDocs,
          { documentId: documentId, reviewed_status: flag },
        ];
      }
    });
  };

  const getReviewStatus = documentId => {
    const status =
      reviewDocs?.find(item => item?.documentId == documentId)
        ?.reviewed_status || false;
    return status;
  };

  const handlePermissionCheckBox = document_id => {
    const updatedPermissionCheckBox = permissionCheckBox.map(doc => {
      if (doc.documentId === document_id) {
        doc.isCustomerProjectDoucmentVisible =
          !doc?.isCustomerProjectDoucmentVisible;
      }
      return doc;
    });

    const existingDocument = docsPayload?.find(
      doc => doc?.documentId === document_id
    );

    let updatedRelatedCheckBoxPayload;
    if (existingDocument) {
      existingDocument.isCustomerProjectDoucmentVisible =
        !existingDocument?.isCustomerProjectDoucmentVisible;
      updatedRelatedCheckBoxPayload = [...docsPayload];
    } else {
      updatedRelatedCheckBoxPayload = [
        ...docsPayload,
        {
          documentId: document_id,
          isCustomerProjectDoucmentVisible: updatedPermissionCheckBox?.find(
            doc => doc?.documentId === document_id
          ).isCustomerProjectDoucmentVisible,
        },
      ];
    }
    setDocsPayload(updatedRelatedCheckBoxPayload);
    setPermissionCheckBox(updatedPermissionCheckBox);
  };

  const handleSelectAll = mode => {
    if (mode == 'installer') {
      let tempInstallerVisibleDocuments = [...installerVisibleDocuments];
      tempInstallerVisibleDocuments.map(
        doc => (doc.isVisibleToInstaller = !installerSelectAllState)
      );
      setInstallerVisibleDocuments(tempInstallerVisibleDocuments);
      setDocsPayload(tempInstallerVisibleDocuments);
      setInstallerSelectAllState(!installerSelectAllState);
    } else if (mode == 'offline') {
      let tempOfflineDocuments = [...offlineDocuments];
      tempOfflineDocuments.map(
        doc => (doc.isProjectDoucmentOffine = !offlineSelectAllState)
      );
      setOfflineDocuments(tempOfflineDocuments);
      setDocsPayload(tempOfflineDocuments);
      setOfflineSelectAllState(!offlineSelectAllState);
    }
  };

  const handleOfflineAvailability = (document_id, relatedDocsMode) => {
    if (!relatedDocsMode) {
      let offlineDocs = _.cloneDeep(offlineDocuments);
      let documentsPayload = _.cloneDeep(docsPayload);

      if (offlineDocs && offlineDocs.length) {
        offlineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isProjectDoucmentOffine = !offlineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isProjectDoucmentOffine;
      }

      if (
        documentsPayload?.filter(doc => doc.documentId == document_id)?.length >
          0 &&
        offlineDocs?.filter(doc => doc.documentId == document_id).length > 0
      ) {
        documentsPayload.filter(
          doc => doc.documentId == document_id
        )[0].isProjectDoucmentOffine = !documentsPayload.filter(
          doc => doc.documentId == document_id
        )[0].isProjectDoucmentOffine;
      } else if (
        documentsPayload?.filter(doc => doc.documentId == document_id)?.length >
          0 &&
        offlineDocs?.filter(doc => doc.documentId != document_id).length > 0
      ) {
        documentsPayload?.splice(
          documentsPayload?.findIndex(doc => doc.documentId == document_id)
        );
      } else {
        documentsPayload.push({
          documentId: document_id,
          isProjectDoucmentOffine:
            offlineDocs?.filter(doc => doc.documentId == document_id)?.[0]
              ?.isProjectDoucmentOffine || false,
        });
      }
      offlineDocs?.filter(doc => doc.isProjectDoucmentOffine === true).length ==
      documents?.filter(doc => doc.documetTypeId === 1).length
        ? setOfflineSelectAllState(true)
        : setOfflineSelectAllState(false);

      setDocsPayload(documentsPayload);
      setOfflineDocuments(offlineDocs);
    } else {
      let relOfflineDocs = _.cloneDeep(relatedOfflineDocuments);
      let relDocumentsPayload = _.cloneDeep(relatedDocsPayload);
      if (relOfflineDocs && relOfflineDocs.length) {
        relOfflineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine = !relOfflineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine;
      }
      if (
        relDocumentsPayload?.filter(doc => doc.documentId == document_id)
          ?.length > 0 &&
        relOfflineDocs?.filter(doc => doc.documentId == document_id).length > 0
      ) {
        relDocumentsPayload.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine = !relDocumentsPayload.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine;
      } else if (
        relDocumentsPayload?.filter(doc => doc.documentId == document_id)
          ?.length > 0 &&
        relOfflineDocs?.filter(doc => doc.documentId != document_id).length > 0
      ) {
        relDocumentsPayload?.splice(
          relDocumentsPayload?.findIndex(doc => doc.documentId == document_id),
          1
        );
      } else {
        relDocumentsPayload.push({
          documentId: document_id,
          isRelatedProjectDoucmentOffine:
            relOfflineDocs?.filter(doc => doc.documentId == document_id)[0]
              .isRelatedProjectDoucmentOffine || false,
        });
      }
      setRelatedProjectDocsOfflineMode(true);
      setRelatedDocsPayload(relDocumentsPayload);
      setRelatedOfflineDocuments(relOfflineDocs);
    }
  };

  const applyVisibilityDocuments = () => {
    setReloadList(false);
    let queryParam = 'visibilityAndOffline';
    if (relatedProjectDocsOfflineMode) {
      queryParam = 'relatedProjectDocuments';
    }

    applyInstallerVisibilityDocuments(
      projectId,
      queryParam,
      relatedProjectDocsOfflineMode
        ? relatedDocsPayload
        : [...docsPayload, ...reviewDocs],
      setLoading,
      setAlert,
      setReloadList
    );
    setReviewDocs([]);
  };

  const thumbnailHandleClick = (document, typeId, projectId = '') => {
    if (document?.documentUrl) {
      if (getUrlExtension(document.documentUrl) === 'pdf') {
        if (typeId == 2) {
          window.open(
            `/project/view/${projectId}/viewProjectDocument/${typeId}/${document.documentId}`,
            '_blank'
          );
        } else {
          window.open(
            `/project/view/${projectId}/viewProjectDocument/${typeId}/${document.documentId}`,
            '_blank'
          );
        }
      } else {
        setOpenDialog(true);
        setDocumentUrlValue(document.documentUrl);
        setDocumentNameValue(document.documentName);
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setAlert('error', 'No document URL Found!', false, true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDocUpload = (
    projectId,
    documentId,
    documentName,
    type,
    isSigned
  ) => {
    setReloadList(false);
    updateDocumentById(
      projectId,
      documentId,
      documentName,
      type,
      documents,
      'Document',
      setDocuments,
      setLoading,
      setAlert,
      setReloadForm,
      isSigned
    ).then(() => {
      setReloadList(true);
    });
  };

  const handleSelectRelatedDoc = documentId => {
    setCopyRelatedDoc(prevState => {
      if (!prevState?.copiedIds?.includes(documentId)) {
        return {
          ...prevState,
          copiedIds: [
            ...new Set([...(prevState?.copiedIds || []), documentId]),
          ],
        };
      } else {
        return {
          ...prevState,
          copiedIds:
            prevState?.copiedIds?.filter(id => id !== documentId) || [],
        };
      }
    });
  };

  const handleSelectAllRelatedDoc = event => {
    if (event.target.checked) {
      setCopyRelatedDoc(prevState => ({
        ...prevState,
        copiedIds: relateDocumentsIds,
      }));
    } else {
      setCopyRelatedDoc(prevState => ({
        ...prevState,
        copiedIds: [],
      }));
    }
  };

  const handleCopyDocuments = () => {
    setReloadList(false);
    copyRelatedDocuments(copyRelatedDoc)
      .then(res => {
        if (res?.status) {
          setAlert('success', res?.message || '', false, true);
          setReloadList(true);
          setCopyRelatedDoc(preValue => ({ ...preValue, copiedIds: [] }));
        }
      })
      .catch(err => {
        setAlert('error', err || '', false, true);
      });
  };

  const handleDocsUpload = event => {
    const files = event.target.files;

    let isValid = true;
    if (event.target.files?.length > 5) {
      isValid = false;
      setDocumentError(DocFileTypeError?.DOC_FILE_LIMIT_ERROR);
    } else {
      if (event.target.files && event.target.files.length > 0) {
        const validExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv'];
        const invalidFiles = Array.from(event.target.files).filter(
          file =>
            !validExtensions.includes(file.name.split('.').pop().toLowerCase())
        );

        if (invalidFiles.length === 0) {
          handleUpload(files, 1);
          setDocumentError(null);
        } else {
          setDocumentError(DocFileTypeError?.DOC_FILE_TYPE_ERROR);
          docInputRef.current.value = '';
        }
      }
    }
  };
  const handleDeleteDoc = async (projectId, id) => {
    try {
      await deleteProjectDocument(projectId, id);
    } catch (ex) {
    } finally {
      setReloadList(prev => !prev);
    }
  };

  const [selectBulkUpload, setSelectBulkUpload] = useState({});

  const handleSelectBulkUploadDoc = (event, documentId) => {
    const idsObject = [];
    const ids = documents?.map(item => {
      if (item?.documetTypeId === 1) {
        idsObject?.push(item?.documentId);
        return true;
      }
    });
    setSelectBulkUpload(prevState => {
      if (event.target.checked) {
        return {
          ...prevState,
          copiedIds: [
            ...new Set([...(prevState?.copiedIds || []), documentId]),
          ],
          count: idsObject?.length || 0,
        };
      } else {
        return {
          ...prevState,
          copiedIds:
            prevState?.copiedIds?.filter(id => id !== documentId) || [],
          count: idsObject?.length || 0,
        };
      }
    });
  };

  const handleSelectAllBulkUpload = event => {
    const idsObject = [];
    const ids = documents?.map(item => {
      if (item?.documetTypeId === 1) {
        idsObject?.push(item?.documentId);
        return true;
      }
    });
    if (event.target.checked) {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: idsObject,
        count: idsObject?.length || 0,
      }));
    } else {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: [],
        count: idsObject?.length || 0,
      }));
    }
  };

  const bulkUpdateCall = () => {
    setReloadList(false);
    let payload = {
      ...uploadContextValue?.data,
      document_category_id:
        uploadContextValue?.data?.document_category_id?.document_category_id ||
        null,
    };
    if (uploadContextValue?.data?.document_store_type) {
      payload.document_store_type =
        uploadContextValue?.data?.document_store_type || null;
    } else {
      delete payload.document_store_type;
    }
    payload = { document_ids: selectBulkUpload?.copiedIds || [], ...payload };
    bulkUpdateService(projectId, payload)
      .then(res => {
        if (res?.status) {
          setSelectBulkUpload({});
          uploadContextValue?.onHide();
          setReloadList(true);
        }
      })
      .catch(err => {
        setAlert('error', err || '', false, true);
      });
  };

  return (
    <>
      <UploadActionContext.Provider
        value={{ uploadContextValue, setUploadContextValue }}
      >
        <UploadStoreTypeDialog
          type={'Document'}
          docInputRef={docInputRef}
          sourceSystemId={formik?.values?.source_system?.source_system_id}
          bulkUpdateHandler={bulkUpdateCall}
        />
      </UploadActionContext.Provider>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "There isn't a viewer available for any other form of document besides PDF. Would you like to download the file?"
          }
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              downloadFile(
                documentUrlValue,
                documentNameValue,
                setDownloadDocumentLoading
              );
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={downloadDocumentLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading ? (
        <Grid container spacing={4} direction="column">
          <Grid item spacing={2} xs={12} sm={12} md={12} lg={12}>
            <Paper classes={{ root: classes.gridPaper }}>
              <Grid container direction="column" spacing={1} className="pr-3">
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    classes={{ root: classes.titleHeaderGrid }}
                  >
                    <Grid item>
                      <Typography variant="h3">Template Documents</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  classes={{ root: classes.docsContainer }}
                >
                  {documents &&
                  documents.filter(doc => doc.documetTypeId === 2).length >
                    0 ? (
                    documents
                      .filter(doc => doc.documetTypeId === 2)
                      .map(document => (
                        <Grid key={document.documentId}>
                          <ThumbnailCard
                            docDetails={document}
                            handleFileDownload={handleFileDownload}
                            handleClick={() =>
                              history.push({
                                pathname: `/project/view/${
                                  projectId ? projectId : ''
                                }/viewProjectDocument/2/${document.documentId}`,
                              })
                            }
                            handleDeleteDoc={handleDeleteDoc}
                            receivedFrom="template"
                          />
                        </Grid>
                      ))
                  ) : (
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Typography>No Template Documents found</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {checkPermission(permissions.viewEditProject.tabDocsView) && (
            <Grid item spacing={2} xs={12} className="document-tab">
              <Paper>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  style={{ width: '99%' }}
                >
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      classes={{ root: classes.titleHeaderGrid }}
                    >
                      <Grid item>
                        <div className="flex justify-content-center pt-3">
                          <Tooltip title="Select All For Bulk Update">
                            <Checkbox
                              onChange={event =>
                                handleSelectAllBulkUpload(event)
                              }
                              checked={
                                selectBulkUpload?.count > 0 &&
                                selectBulkUpload?.count ==
                                  selectBulkUpload?.copiedIds?.length
                              }
                            />
                          </Tooltip>
                          <h3>Project Documents</h3>
                        </div>
                      </Grid>
                      <Grid style={{ display: 'flex', gap: '10px' }}>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              uploadContextValue?.onShow();
                              setUploadContextValue(preValue => ({
                                ...preValue,
                                req_from: 'bulk_upload',
                              }));
                            }}
                            disabled={
                              (selectBulkUpload?.copiedIds || [])?.length > 0
                                ? false
                                : true
                            }
                          >
                            Bulk Update
                          </Button>
                        </Grid>

                        {formik?.values?.project_type?.project_type_id !==
                          3 && (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                getFromSource(
                                  projectId,
                                  setLoading,
                                  setAlert,
                                  setReloadList
                                );
                                setReloadList(false);
                              }}
                            >
                              Get From Source
                            </Button>
                          </Grid>
                        )}

                        {documents?.length > 0 && installerVisible && (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={applyVisibilityDocuments}
                            >
                              Apply
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    classes={{ root: classes.docsContainer }}
                  >
                    <Grid>
                      <Grid item>
                        <input
                          accept=".pdf, .doc, .docx, .xls, .xlsx, .csv"
                          className={classes.input}
                          id="pdf-icon-button-file"
                          type="file"
                          multiple={
                            uploadContextValue?.data?.document_store_type !==
                            'COMPLETE'
                          }
                          onClick={event => {
                            event.target.value = null;
                          }}
                          ref={docInputRef}
                          onChange={event => {
                            handleDocsUpload(event);
                          }}
                        />
                        <label
                          className={classes.addDocumentLabel}
                          onClick={() =>
                            checkPermission(
                              permissions.viewEditProject.tabDocsAddProjectDocs
                            )
                              ? uploadContextValue?.onShow()
                              : uploadContextValue?.onHide()
                          }
                        >
                          <ThumbnailCard
                            showDocStoreType={true}
                            handleClick={documenT =>
                              uploadContextValue?.onShow()
                            }
                            handleDeleteDoc={handleDeleteDoc}
                          />
                        </label>
                      </Grid>
                    </Grid>
                    <ConfirmDialog />
                    {documents &&
                      documents.filter(doc => doc.documetTypeId === 1).length >
                        0 &&
                      documents
                        .filter(doc => doc.documetTypeId === 1)
                        .map(document => (
                          <Grid item key={document.documentId}>
                            <div style={{ width: '250px !important' }}>
                              <ThumbnailCard
                                documentVisibleToInstaller={
                                  documentVisibleToInstaller
                                }
                                docDetails={document}
                                handleFileDownload={handleFileDownload}
                                handleClick={docTemplateId =>
                                  thumbnailHandleClick(
                                    document,
                                    1,
                                    document?.projectId || '',
                                    docTemplateId
                                  )
                                }
                                handleInstallerVisiblity={
                                  handleInstallerVisiblity
                                }
                                handleOfflineAvailability={
                                  handleOfflineAvailability
                                }
                                handleSelectAll={handleSelectAll}
                                installerVisibleDocuments={
                                  installerVisibleDocuments
                                }
                                offlineDocuments={offlineDocuments}
                                setInstallerVisibleDocuments={
                                  setInstallerVisibleDocuments
                                }
                                installerVisible={installerVisible}
                                reviewPermission={reviewPermission}
                                showDocStoreType={true}
                                categoryVisibility={true}
                                handleDocUpload={(
                                  projectId,
                                  documentId,
                                  documentName,
                                  type,
                                  isSigned
                                ) =>
                                  handleDocUpload(
                                    projectId,
                                    documentId,
                                    documentName,
                                    type,
                                    isSigned
                                  )
                                }
                                setPermissionCheckBox={setPermissionCheckBox}
                                permissionCheckBox={permissionCheckBox}
                                handlePermissionCheckBox={
                                  handlePermissionCheckBox
                                }
                                handleDeleteDoc={handleDeleteDoc}
                                handleReviewAvailability={
                                  handleReviewAvailability
                                }
                                reviewDocs={reviewDocs}
                                getReviewStatus={getReviewStatus}
                                handleSelectBulkUploadDoc={
                                  handleSelectBulkUploadDoc
                                }
                                selectBulkUpload={selectBulkUpload}
                              />
                            </div>
                          </Grid>
                        ))}
                  </Grid>
                  {documentError && (
                    <p className="ml-5 text-red-600">{`${documentError}`}</p>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
          {checkPermission(permissions.viewEditProject.tabDocsViewRelated) && (
            <Grid item spacing={2} xs={12}>
              <Paper classes={{ root: classes.gridPaper }}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      classes={{ root: classes.titleHeaderGrid }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Related Project Documents
                        </Typography>
                      </Grid>
                      <Grid style={{ display: 'flex', gap: '10px' }}>
                        <div className="flex align-items-center flex-wrap">
                          <Checkbox
                            onChange={event => handleSelectAllRelatedDoc(event)}
                            checked={
                              relateDocumentsIds?.length > 0 &&
                              copyRelatedDoc?.copiedIds?.length > 0 &&
                              relateDocumentsIds?.length ===
                                copyRelatedDoc?.copiedIds?.length
                            }
                            disabled={
                              relateDocumentsIds?.length > 0 ? false : true
                            }
                          />
                          Copy All
                        </div>
                        <PFButton
                          label="Copy to Current Job"
                          disabled={
                            copyRelatedDoc?.copiedIds?.length > 0 ? false : true
                          }
                          onClick={handleCopyDocuments}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} className="flex mt-3">
                        <Autocomplete
                          disableClearable
                          id="combo-box-demo"
                          options={relatedPOResponse?.items || []}
                          getOptionLabel={option =>
                            option.project_number + ' ' + option.project_type
                          }
                          openOnFocus={true}
                          value={
                            relatedPOResponse?.items?.filter(
                              rel => rel.project_id === selectedJob?.project_id
                            )[0]
                          }
                          onChange={(event, value) => handleJobSelection(value)}
                          renderInput={params => (
                            <TextField {...params} label="Select Job" />
                          )}
                          className="w-12 md:w-6 xl:w-4"
                        />

                        {relateDocuments?.length > 0 &&
                          installerVisible &&
                          selectedJob?.project_type ==
                            PROJECT_TYPES?.MEASUREMENT && (
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              severity="primary"
                              className="ml-3"
                              onClick={applyVisibilityDocuments}
                            >
                              Apply
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {relatedDocloading ? (
                    <Grid container justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      direction="row"
                      wrap="nowrap"
                      classes={{ root: classes.docsContainer }}
                    >
                      {relateDocuments &&
                      relateDocuments.filter(doc => doc.documetTypeId === 1)
                        .length > 0 ? (
                        relateDocuments
                          .filter(doc => doc.documetTypeId === 1)
                          .map(document => (
                            <ThumbnailCard
                              key={document.documentId}
                              docDetails={document}
                              handleFileDownload={
                                handleFileDownloadRelatedDocument
                              }
                              handleClick={docTemplateId => {
                                thumbnailHandleClick(
                                  document,
                                  1,
                                  document?.projectId || '',
                                  docTemplateId
                                );
                              }}
                              handleOfflineAvailability={
                                handleOfflineAvailability
                              }
                              handleSelectAll={handleSelectAll}
                              relatedOfflineDocumentsMode={true}
                              offlineDocuments={relatedOfflineDocuments}
                              installerVisible={installerVisible}
                              selectedJob={selectedJob}
                              copyRelatedDoc={copyRelatedDoc}
                              handleSelectRelatedDoc={handleSelectRelatedDoc}
                              handleDeleteDoc={handleDeleteDoc}
                              receivedFrom="relatedDocuments"
                            />
                          ))
                      ) : (
                        <Grid container justifyContent="center">
                          <span className={classes.emptyText}>
                            No Documents found!
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LOWES && (
            <>
              <ProjectConfigDetails projectId={projectId} />
              <ProjectMaterials projectId={projectId} />
            </>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default Docs;
