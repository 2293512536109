import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserTracing } from '@sentry/tracing';

import AppWrapper from './AppWrapper';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);
ReactDOM.render(<AppWrapper />, document.getElementById('root'));
