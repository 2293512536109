import React, { useEffect, useState, useContext, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';

import { PFDynamicDropdown } from '../../../shared/PFPrime/PFDynamicDropdown';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import { ShippingContext } from '../ShippingContainer';
import {
  createShipmentService,
  getAllTechnicianListService,
  shippingCompanyCreateService,
  shippingCompanyListService,
  updateShipmentService,
} from '../services/shipping.service';
import { fetchWarehouseList } from '../../ProjectItemsNewUI/ProjectItemsService';
import PFButton from '../../../shared/PFPrime/PFButton';
import { cleanedPayload } from '../helpers/helper';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import { dateFormatter } from '../../../../utils/Helpers';

export const shippingBody = () => {
  const { shippingContextValue, setShippingContextValue } =
    useContext(ShippingContext);

  const [disabledMode, setDisabledMode] = useState(false);
  useEffect(() => {
    shippingContextValue?.visibleMode?.visible &&
      setDisabledMode(
        shippingContextValue?.visibleMode?.visible === 'view' ? true : false
      );
  }, [shippingContextValue?.visibleMode?.visible]);

  const storeShipment = async payload => {
    payload.received_package = payload?.received_package || 0;
    if (shippingContextValue?.visibleMode?.visible === 'add') {
      const store = await createShipmentService(
        shippingContextValue?.project_id,
        shippingContextValue?.project_shipping_id,
        payload
      );
      if (store?.status) {
        shippingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Shipment added successfully',
        });
      }
    }
    if (shippingContextValue?.visibleMode?.visible === 'edit') {
      const store = await updateShipmentService(
        shippingContextValue?.project_id,
        shippingContextValue?.project_shipping_id,
        shippingContextValue?.rest?.project_shipment_id,
        payload
      );
      if (store?.status) {
        shippingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Shipment updated successfully',
        });
      }
    }
    setShippingContextValue(preValue => ({
      ...preValue,
      deleteFlag: true,
      listReload: true,
    }));
    shippingContextValue?.hide();
  };

  const maxValidation = () => {
    const expected_package = parseFloat(
      shippingContextValue?.shippingData?.expected_package || 0
    );
    const received_package = parseFloat(
      shippingContextValue?.shippingData?.received_package || 0
    );
    let result = expected_package - received_package;
    if (shippingContextValue?.visibleMode?.visible === 'edit') {
      result =
        result + shippingContextValue?.visibleMode?.data?.received_package || 0;
    }
    return result;
  };

  const {
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    handleChange,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipping_company_id: '',
      tracking: '',
      eta: null,
      follow_up_date: null,
      delivery_date: null,
      received_date: null,
      received_package: 0,
      warehouse_id: '',
      shipping_technician_id: '',
      received_by: '',
      shipment_note: '',
    },
    validationSchema: Yup.object().shape({
      received_package: Yup.number().max(
        maxValidation(),
        `The entered value must be ${maxValidation()} or less`
      ),
    }),
    onSubmit: values => {
      storeShipment(values);
    },
  });

  useEffect(() => {
    if (shippingContextValue?.visibleMode?.visible !== 'add') {
      shippingContextValue?.visibleMode?.data &&
        setValues(shippingContextValue?.visibleMode?.data);
    }
  }, [shippingContextValue?.visibleMode?.visible]);

  const companyList = async (event = null, setItems) => {
    const query = event?.query || '';
    const res = await shippingCompanyListService(query);
    if (res?.status) {
      setItems(res?.data?.length > 0 ? res?.data : []);
    }
  };

  const shippingCompanyCreate = async (name, setValue, setItems) => {
    const res = await shippingCompanyCreateService({ company_name: name });
    if (res?.status) {
      setValue(res?.data);
      companyList(res?.data?.company_name, setItems);
      shippingContextValue?.toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Company added successfully',
      });
    }
  };
  const [warehouse, setWarehouse] = useState([]);
  const wareHouseList = async () => {
    const response = await fetchWarehouseList();
    setWarehouse(response);
  };

  const [technician, setTechnician] = useState([]);
  const technicianList = async () => {
    const response = await getAllTechnicianListService();
    setTechnician(response);
  };

  useEffect(() => {
    wareHouseList();
    technicianList();
  }, []);

  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    setFieldValue(
      'shipping_company_id',
      selectedCompany?.shipping_company_id || ''
    );
  }, [selectedCompany]);

  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    const isEmpty = Object.keys(cleanedPayload(values)).length === 0;
    setSaveDisabled(true);
    if (!isEmpty) {
      setSaveDisabled(false);
    }
  }, [values]);

  return (
    <div className="py-3 grid">
      <div className="col-12 md:col-12 lg:col-4">
        <PFDynamicDropdown
          listCallback={companyList}
          field="company_name"
          storeCallback={shippingCompanyCreate}
          defaultValue={
            values?.shippingCompany ||
            shippingContextValue?.rest?.shippingCompany ||
            ''
          }
          label="Shipping Company"
          selectedData={setSelectedCompany}
          disabled={disabledMode}
        />
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label">
          <PFInputText
            name="tracking"
            value={values?.tracking || ''}
            onChange={handleChange}
            disabled={disabledMode}
          />
          <label htmlFor="Tracking">Tracking</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label">
          <PFInputNumber
            name="expected_package"
            value={values?.expected_package || ''}
            onChange={(name, value) => {
              setFieldValue(name, value || '0');

              setShippingContextValue(preValue => ({
                ...preValue,
                shippingData: {
                  ...shippingContextValue?.shippingData,
                  expected_package: value,
                },
              }));
            }}
            disabled={disabledMode}
          />
          <label htmlFor="expected_package"># of PKGS Expected</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label  flex-1 flex">
          <PFCalendar
            name="eta"
            appendTo={null}
            disabled={disabledMode}
            onChange={e =>
              setFieldValue(
                'eta',
                (e?.target?.value &&
                  dateFormatter(e?.target?.value, 'YYYY-MM-DD')) ||
                  null
              )
            }
            value={
              values?.eta
                ? new Date(dateFormatter(values?.eta, 'MM/DD/YYYY'))
                : null
            }
          />
          <label htmlFor="eta">ETA Date</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label   flex-1 flex">
          <PFCalendar
            name="follow_up_date"
            appendTo={null}
            disabled={disabledMode}
            dateFormat={'mm-dd-yy'}
            onChange={e =>
              setFieldValue(
                'follow_up_date',
                (e?.target?.value &&
                  dateFormatter(e?.target?.value, 'YYYY-MM-DD')) ||
                  null
              )
            }
            value={
              values?.follow_up_date
                ? new Date(dateFormatter(values?.follow_up_date, 'MM/DD/YYYY'))
                : null
            }
          />
          <label htmlFor="followUpDate">Follow Up Date</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label  flex-1 flex">
          <PFCalendar
            name="received_date"
            appendTo={null}
            disabled={disabledMode}
            dateFormat={'mm-dd-yy'}
            onChange={e =>
              setFieldValue(
                'received_date',
                (e?.target?.value &&
                  dateFormatter(e?.target?.value, 'YYYY-MM-DD')) ||
                  null
              )
            }
            value={
              values?.received_date
                ? new Date(dateFormatter(values?.received_date, 'MM/DD/YYYY'))
                : null
            }
          />
          <label htmlFor="followUpDate">Date Received</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label flex-1 flex">
          <PFInputNumber
            name="received_package"
            value={values?.received_package || ''}
            onChange={(name, value) => setFieldValue(name, value || '0')}
            disabled={disabledMode}
          />
          <label htmlFor="followUpDate"># of package Received</label>
        </span>

        <PFFormikError
          touched={touched}
          errors={errors}
          field="received_package"
        />
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label flex-1 flex">
          <PFDropdown
            name="warehouse_id"
            value={values?.warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            disabled={disabledMode}
            options={warehouse}
            optionLabel="name"
            optionValue="master_warehouse_id"
            showClear={true}
            filter={true}
          />
          <label htmlFor="warehouse_id">Receipt location</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label flex-1 flex">
          <PFDropdown
            name="shipping_technician_id"
            value={values?.shipping_technician_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            disabled={disabledMode}
            options={technician}
            optionLabel="full_name"
            optionValue="user_id"
            filter={true}
            showClear={true}
          />
          <label htmlFor="followUpDate">Shipping Technician</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-4">
        <span className="p-float-label flex-1 flex">
          <PFInputText
            name="received_by"
            value={values?.received_by || ''}
            onChange={handleChange}
            disabled={disabledMode}
          />
          <label htmlFor="followUpDate">Received By</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-12">
        <span className="p-float-label">
          <InputTextarea
            name="shipment_note"
            className="w-full"
            rows={5}
            cols={30}
            disabled={disabledMode}
            onChange={e =>
              setFieldValue('shipment_note', e?.target?.value || '')
            }
            value={values?.shipment_note || ''}
          />
          <label htmlFor="note">Note</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-12">
        <div className="flex justify-content-end">
          <PFButton
            label="Cancel"
            primary
            outlined
            onClick={() => shippingContextValue?.hide()}
          />
          <PFButton
            label="Save"
            primary
            type="button"
            className="mx-1"
            disabled={disabledMode || saveDisabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
