import React, { useRef, useState, useEffect } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import momentPlugin from '@fullcalendar/moment';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import rrulePlugin from '@fullcalendar/rrule';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import {
  checkPermission,
  doesKeyExistInArray,
  formatTimeHM,
  getDefaultSchedulerView,
  getLocalClientDetails,
  getUniqueArrayItemsByKey,
  selectedRapidSlot,
} from '../../../utils/Helpers';
import { SCHEDULER_CARD_VIEW_OPTION } from '../constants/constants';
import permissions from '../../../config/permissions';

import { eventColorGenerator } from './color-codes';
import { EVENT_COLORS, EVENT_TYPES } from '../constants/constants';
import { mapProjectTypeToColor } from '../helpers/helpers';

import { DEFAULT_COLORS_BY_PROJECT_TYPE } from '../../../constants';
import { useStyles } from '../CalenderContainer.styles';

const getPeriod = type => {
  let period;
  switch (type) {
    case 'day':
      period = 'D';
      break;
    case 'week':
      period = 'W';
      break;
    case 'month':
      period = 'M';
      break;
  }
  return period;
};

const NewSchedulerCalendar = ({
  onEventClick,
  reportData,
  datePeriodForTechnicianView,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { eventsList, selectedInstallers, viewChangeFlag, isWeekendsVisible } =
    useSelector(state => state.newScheduler);

  const calendarRef = useRef(null);
  const [cardVisibility, setCardVisibility] = useState(
    getLocalClientDetails().scheduler_config?.calender_view?.card_visibility
      ? getLocalClientDetails().scheduler_config?.calender_view?.card_visibility
      : null
  );
  const [colorsByProjectType, setColorsByProjectType] = useState(
    DEFAULT_COLORS_BY_PROJECT_TYPE
  );
  const mapEventsDataForCalendar = (items, selectedInstallers) => {
    const selectedInstallerIds = selectedInstallers?.map(i => i?.id);
    const mappedEvents = items
      ?.map(i => {
        const isTimeOffEvent =
          i?.time_off_start_date &&
          i?.time_off_end_date &&
          i?.project_id === null &&
          (i?.status_id === 86 || i?.status_id === 88);
        return {
          isTimeOffEvent,
          start: isTimeOffEvent ? i?.time_off_start_date : i?.schedule_date,
          end: isTimeOffEvent ? i?.time_off_end_date : i.schedule_end_date,
          installerName: i?.installer_name,
          installerId: i?.installer_id,
          color: isTimeOffEvent ? '#ddd' : eventColorGenerator(i?.installer_id),
          textColor: isTimeOffEvent ? '#000' : undefined,
          projectId: i?.project_id ? i?.project_id : null,
          sourceSystemId: i?.source_system_id ? i?.source_system_id : null,
          rescheduleReason: i?.reschedule_reason ? i?.reschedule_reason : null,
          clientName: i?.client_name,
          projectType: i?.project_type,
          category: i?.category,
          projectStatus: i?.project_status,
          storeNumber: i?.store_number ? i?.store_number : null,
          statusId: i?.status_id,
          timeOffNote: i?.time_off_note,
          timeOffRequestId: i?.user_time_off_request_id,
          projectNumber: i?.project_number ? i?.project_number : null,
          projectDesc: i?.project_desc,
          totalSale: i?.total_sale_amount ? i?.total_sale_amount : '',
          totalRevenue: i?.total_revenue ? i?.total_revenue : '',
          address: i?.address,
          mobilePhone: i?.mobile_phone,
          time:
            i?.schedule_date && i?.schedule_end_date
              ? formatTimeHM(i?.schedule_date) +
                ' - ' +
                formatTimeHM(i?.schedule_end_date)
              : '',
          basicLaborSum: i?.basic_labor_sum,
        };
      })
      .filter(
        i =>
          i?.start !== null &&
          i?.end !== null &&
          selectedInstallerIds?.includes(i?.installerId)
      );

    let eventData =
      items
        ?.map((i, index) => {
          let eventColor = EVENT_COLORS[1];
          if (!!i?.project_type && !!colorsByProjectType) {
            eventColor = mapProjectTypeToColor(
              colorsByProjectType,
              i?.project_type,
              i?.project_status,
              i?.color_code
            );
          }
          const isTimeOffEvent =
            i?.time_off_start_date &&
            i?.time_off_end_date &&
            i?.project_id === null &&
            (i?.status_id != 86 || i?.status_id != 88);
          return {
            isTimeOffEvent,

            id: i.project_id,
            title: i.project_desc || '',
            resourceId: i.installer_id,
            color: isTimeOffEvent
              ? '#ddd'
              : eventColorGenerator(i?.installer_id),
            textColor: isTimeOffEvent ? '#000' : undefined,
            border: 'none',
            installerName: i?.installer_name,
            installerId: i?.installer_id,
            start: isTimeOffEvent ? i?.time_off_start_date : i?.schedule_date,
            end: isTimeOffEvent ? i?.time_off_end_date : i.schedule_end_date,
            projectId: i?.project_id ? i?.project_id : null,
            sourceSystemId: i?.source_system_id ? i?.source_system_id : null,
            rescheduleReason: i?.reschedule_reason
              ? i?.reschedule_reason
              : null,

            clientName: i?.client_name,
            projectType: i?.project_type,
            category: i?.category,
            projectStatus: i?.project_status,
            storeNumber: i?.store_number ? i?.store_number : null,
            statusId: i?.status_id,
            timeOffNote: i?.time_off_note,
            timeOffRequestId: i?.user_time_off_request_id,
            projectNumber: i?.project_number ? i?.project_number : null,
            projectDesc: i?.project_desc,
            totalSale: i?.total_sale_amount ? i?.total_sale_amount : '',
            totalRevenue: i?.total_revenue ? i?.total_revenue : '',
            address: i?.address,
            mobilePhone: i?.mobile_phone,
            time:
              moment(i.schedule_date).format('hh:mm A') +
              ' - ' +
              moment(i.schedule_end_date).format('hh:mm A'),

            background:
              'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
          };
        })
        .filter(i => i?.start !== null && i?.end !== null && i?.id) || [];

    let blockedEvents =
      (items?.length > 0 &&
        items
          ?.filter(
            i =>
              i?.is_blocked_time === 1 &&
              i?.time_off_start_date !== null &&
              i?.time_off_end_date !== null &&
              i?.user_time_off_request_id
          )
          ?.map(i => {
            const isTimeOffEvent =
              i?.time_off_start_date &&
              i?.time_off_end_date &&
              // i?.project_id === null &&
              i?.is_blocked_time === 1 &&
              (i?.status_id === 86 || i?.status_id === 88);
            const eventColor = EVENT_COLORS[4];
            return {
              isTimeOffEvent,
              timeOffNote: i?.time_off_note || '',
              resourceId: i?.installer_id,
              color: isTimeOffEvent
                ? '#ddd'
                : eventColorGenerator(i?.installer_id),
              textColor: isTimeOffEvent ? '#000' : undefined,
              start: moment(i?.time_off_start_date).format(),
              end: moment(i?.time_off_end_date).format(),
              allDay: i?.full_day === 1 ? true : false,
              eventType: EVENT_TYPES.BLOCK,
              isDisabled: true,
              timeOffRequestId: i?.user_time_off_request_id,
              editable: false,
              durationEditable: false,
              isBlockedTime: i?.is_blocked_time,
            };
          })) ||
      [];

    // Grey lines for tentative time off
    let timeoffTentativeEvents =
      (items?.length > 0 &&
        items
          ?.filter(
            i =>
              i?.time_off_start_date &&
              i?.time_off_end_date &&
              i?.status_id === 86 &&
              i?.is_blocked_time !== 1
          )
          .reduce((filter, current) => {
            var dk = filter?.find(
              item =>
                item.time_off_start_date === current.time_off_start_date &&
                item.time_off_end_date === current.time_off_end_date &&
                item.project_number === current.project_number
            );
            if (!dk) {
              return filter.concat([current]);
            } else {
              return filter;
            }
          }, [])

          .map(i => {
            let eventColor =
              EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS?.length)];
            if (i?.time_off_start_date && i?.time_off_end_date) {
              const time_off_start_date = i?.time_off_start_date;
              const time_off_end_date = i?.time_off_end_date;
              const unformattedEndDate = moment(time_off_start_date);
              const endDateHour = moment(time_off_end_date).get('hour');
              const endDateMinute = moment(time_off_end_date).get('minute');
              const endDate = unformattedEndDate.set({
                hour: endDateHour,
                minute: endDateMinute,
              });

              const startDate = moment(time_off_start_date);
              const durationInHour = moment.duration(endDate.diff(startDate));
              const splitHours = durationInHour.asHours().toString().split('.');
              const unformattedHours = splitHours[0];
              let minute = '00';

              if (splitHours.length > 1) {
                minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
              }
              const duration = unformattedHours
                .padStart(2, '0')
                .concat(':', minute);
              const isTimeOffEvent =
                i?.time_off_start_date &&
                i?.time_off_end_date &&
                i?.is_blocked_time != 1 &&
                i?.status_id === 86;
              const eventColor = EVENT_COLORS[4];
              return {
                isTimeOffEvent,
                timeOffNote: i?.time_off_note || '',
                resourceId: i?.installer_id,
                color: isTimeOffEvent
                  ? '#ddd'
                  : eventColorGenerator(i?.installer_id),
                textColor: isTimeOffEvent ? '#000' : undefined,
                border: 'none',
                installerId: i?.installer_id,
                projectId: i?.project_id ? i?.project_id : null,
                start: isTimeOffEvent
                  ? i?.time_off_start_date
                  : i?.schedule_date,
                end: isTimeOffEvent
                  ? i?.time_off_end_date
                  : i.schedule_end_date,
                allDay: i.full_day == 1 ? true : false,
                eventType: 'timeoff',
                overlap: true,
                rrule: {
                  freq: 'weekly',
                  interval: i?.is_recurring_every_other_week,
                  byweekday: [
                    ...(i?.sun ? ['su'] : []),
                    ...(i?.mon ? ['mo'] : []),
                    ...(i?.tue ? ['tu'] : []),
                    ...(i?.wed ? ['we'] : []),
                    ...(i?.thus ? ['th'] : []),
                    ...(i?.fri ? ['fr'] : []),
                    ...(i?.sat ? ['sa'] : []),
                  ],
                  until: moment(i.time_off_end_date).format(),
                  dtstart: moment(i.time_off_start_date).format(),
                },
                duration,
                isDisabled: true,
                timeOffRequestId: i?.user_time_off_request_id,
              };
            }
          })) ||
      [];

    // Grey boxes for approved time off
    let timeoffApprovedEvents =
      (items?.length > 0 &&
        items
          ?.filter(
            i =>
              i?.time_off_start_date &&
              i?.time_off_end_date &&
              i?.status_id === 88 &&
              i?.is_blocked_time !== 1
          )
          .reduce((filter, current) => {
            var dk = filter?.find(
              item =>
                item?.time_off_start_date === current?.time_off_start_date &&
                item?.time_off_end_date === current?.time_off_end_date &&
                item?.project_number === current?.project_number
            );
            if (!dk) {
              return filter.concat([current]);
            } else {
              return filter;
            }
          }, [])

          .map(i => {
            let eventColor =
              EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS?.length)];
            if (i?.time_off_start_date && i?.time_off_end_date) {
              const time_off_start_date = i?.time_off_start_date;
              const time_off_end_date = i?.time_off_end_date;
              const unformattedEndDate = moment(time_off_start_date);
              const endDateHour = moment(time_off_end_date).get('hour');
              const endDateMinute = moment(time_off_end_date).get('minute');
              const endDate = unformattedEndDate.set({
                hour: endDateHour,
                minute: endDateMinute,
              });

              const startDate = moment(time_off_start_date);
              const durationInHour = moment.duration(endDate.diff(startDate));
              const splitHours = durationInHour.asHours().toString().split('.');
              const unformattedHours = splitHours[0];
              let minute = '00';

              if (splitHours.length > 1) {
                minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
              }
              const duration = unformattedHours
                .padStart(2, '0')
                .concat(':', minute);
              const isTimeOffEvent =
                i?.time_off_start_date &&
                i?.time_off_end_date &&
                i?.is_blocked_time != 1 &&
                i?.status_id === 88;
              const availabilityUpdate = {
                isTimeOffEvent,
                timeOffNote: i?.time_off_note || '',
                resourceId: i?.installer_id,
                color: isTimeOffEvent
                  ? '#ddd'
                  : eventColorGenerator(i?.installer_id),
                textColor: isTimeOffEvent ? '#000' : undefined,
                border: 'none',
                installerId: i?.installer_id,
                project_id: i?.project_id,
                start: moment(i?.time_off_start_date).format(),
                end: moment(i?.time_off_end_date).format(),
                allDay: i?.full_day == 1 ? true : false,
                eventType: 'timeoff',
                rrule: {
                  freq: 'weekly',
                  interval: i?.is_recurring_every_other_week,
                  byweekday: [
                    ...(i?.sun ? ['su'] : []),
                    ...(i?.mon ? ['mo'] : []),
                    ...(i?.tue ? ['tu'] : []),
                    ...(i?.wed ? ['we'] : []),
                    ...(i?.thus ? ['th'] : []),
                    ...(i?.fri ? ['fr'] : []),
                    ...(i?.sat ? ['sa'] : []),
                  ],
                  until: moment(i?.time_off_end_date).format(),
                  dtstart: moment(i?.time_off_start_date).format(),
                },
                overlap: true,
                isDisabled: true,
                duration,
                timeOffRequestId: i?.user_time_off_request_id,
                is_customer_requested: i?.is_customer_requested,
              };
              return availabilityUpdate;
            }
          })) ||
      [];

    return [
      ...eventData,
      ...getUniqueArrayItemsByKey(timeoffApprovedEvents, 'timeOffRequestId'),
      ...getUniqueArrayItemsByKey(timeoffTentativeEvents, 'timeOffRequestId'),
      ...getUniqueArrayItemsByKey(blockedEvents, 'timeOffRequestId'),
    ];
  };
  useEffect(() => {
    if (selectedInstallers?.length === 0) {
      dispatch(
        setNewSchedulerComponent({
          eventsList: [],
        })
      );
    }
  }, [selectedInstallers]);
  const RenderEventContent = eventInfo => {
    const {
      installerName,
      clientName,
      projectType,
      category,
      projectStatus,
      storeNumber,
      isTimeOffEvent,
      timeOffNote,
      time,
      projectNumber,
      projectDesc,
      totalSale,
      totalRevenue,
      address,
      mobilePhone,
      basicLaborSum,
    } = eventInfo?.event?.extendedProps;
    let eventClasses = 'event-wrapper w-full h-full flex flex-column ';
    if (!isTimeOffEvent) {
      eventClasses = `${eventClasses} cursor-pointer overflow-y-auto overflow-x-hidden`;
    }
    const randomTooltipId = Math.random().toString(36).slice(-5);

    return (
      <>
        <Tooltip target={`[data-tt-id="${randomTooltipId}"]`} mouseTrack>
          <div className="flex flex-column">
            <span className="font-bold">{installerName}</span>
            {!isTimeOffEvent && basicLaborSum && (
              <span>Qty : {basicLaborSum && `${basicLaborSum}`}</span>
            )}
            {isTimeOffEvent && timeOffNote && <span>{timeOffNote}</span>}
            {!isTimeOffEvent && (
              <span> Description :{projectDesc && `${projectDesc}`}</span>
            )}

            {!isTimeOffEvent && <span>{`Time : ${time}`}</span>}
            {!isTimeOffEvent && storeNumber && (
              <span>{`Store # ${storeNumber}`}</span>
            )}
            {!isTimeOffEvent && projectType && (
              <span>{`Type : ${projectType}`}</span>
            )}
            {!isTimeOffEvent && category && (
              <span>{`Category : ${category}`}</span>
            )}
            {!isTimeOffEvent && projectStatus && (
              <span>{`Status : ${projectStatus}`}</span>
            )}
            {!isTimeOffEvent &&
              checkPermission(
                permissions?.projectItems?.viewProjectLevelCostField
              ) && <span>{`Total Sale $ : ${totalSale}`}</span>}
            {!isTimeOffEvent &&
              checkPermission(
                permissions?.projectItems?.viewProjectLevelCostField
              ) && <span>{`Total Revenue $ : ${totalRevenue}`}</span>}
            {!isTimeOffEvent && projectNumber && (
              <span>{`Project No : ${projectNumber}`}</span>
            )}

            {!isTimeOffEvent && clientName && (
              <span>{`Client Name : ${clientName}`}</span>
            )}
            {!isTimeOffEvent && (
              <span>Address : {address && `${address}`}</span>
            )}
            {!isTimeOffEvent && (
              <span>Phone : {mobilePhone && `${mobilePhone}`}</span>
            )}
          </div>
        </Tooltip>
        <div className={eventClasses} data-tt-id={randomTooltipId}>
          <span className="font-bold text-sm">{installerName}</span>
          {!isTimeOffEvent &&
            basicLaborSum &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.basic_labor_quantity
            ) && <span>Qty : {basicLaborSum || 0}</span>}
          {isTimeOffEvent && timeOffNote && <span>{timeOffNote}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.description
            ) && <span>Description :{projectDesc && `${projectDesc}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.time
            ) && <span>{`Time : ${time}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.store
            ) &&
            storeNumber && <span>{`Store # ${storeNumber}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.project_type
            ) &&
            projectType && <span>{`Type : ${projectType}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.category
            ) &&
            category && <span>{`Category : ${category}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.status
            ) &&
            projectStatus && <span>{`Status : ${projectStatus}`}</span>}

          {!isTimeOffEvent &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.total_sale
            ) && <span>{`Total Sale $ : ${totalSale}`}</span>}
          {!isTimeOffEvent &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.total_revenue
            ) && <span>{`Total Revenue $ : ${totalRevenue}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.project_number
            ) &&
            projectNumber && <span>{`Project No : ${projectNumber}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.client_name
            ) &&
            clientName && <span>{`Client Name : ${clientName}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.address
            ) && <span>Address:{address && `${address}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.phone
            ) && <span>Phone : {mobilePhone && `${mobilePhone}`}</span>}
        </div>
      </>
    );
  };

  const handleViewChange = view => {
    let params;
    let calendarApi = !!calendarRef && calendarRef?.current?.getApi();
    switch (view) {
      case 'prev':
        calendarApi?.prev();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'next':
        calendarApi?.next();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'day':
        calendarApi?.changeView('day');
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'D',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'week':
        calendarApi?.changeView('week');
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'W',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'month':
        calendarApi?.changeView('month');
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'M',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
    }
  };

  const handleSelect = selectInfo => {
    const { minutesDifference, startSlotTime } = selectedRapidSlot(selectInfo);
    dispatch(
      setNewSchedulerComponent({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: selectInfo.start,
          end: minutesDifference > 0 ? selectInfo.end : startSlotTime,
        },
      })
    );
  };

  const handleNewEvent = () => {
    dispatch(
      setNewSchedulerComponent({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: new Date(),
          end: new Date(),
        },
      })
    );
  };

  const handleDrop = drop => {
    const dropDialogData = reportData?.find(
      data =>
        data?.project_id ===
        parseInt(drop?.draggedEl?.attributes['data-event']?.value)
    );
    const endDateTime = new Date(drop?.dateStr).getTime() + 3600000;
    const dropInfoObject = {
      start: new Date(drop?.dateStr).toISOString(),
      end: new Date(endDateTime).toISOString(),
    };
    dispatch(
      setNewSchedulerComponent({
        dropDialogData,
        dropInfo: dropInfoObject,
        isDropDialogOpen: true,
      })
    );
  };

  return (
    <div className="flex flex-column w-full calendar-container">
      <FullCalendar
        timeZone="local"
        ref={calendarRef}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        selectable={true}
        plugins={[timeGridPlugin, interactionPlugin]}
        views={{
          month: {
            type: 'timeGrid',
            duration: { month: 1 },
            buttonText: 'Month',
            dayHeaderFormat: { day: 'numeric', weekday: 'short' },
          },
          week: {
            type: 'timeGrid',
            duration: { weeks: 1 },
            buttonText: 'Week',
          },
          day: {
            type: 'timeGrid',
            duration: { days: 1 },
            buttonText: 'Day',
          },
        }}
        customButtons={{
          prevButton: {
            icon: 'fc-icon-chevron-left',
            click: () => handleViewChange('prev'),
          },
          nextButton: {
            icon: 'fc-icon-chevron-right',
            click: () => handleViewChange('next'),
          },
          newEvent: {
            text: 'New Event',
            click: () => handleNewEvent(),
          },
          day: {
            text: 'Day',
            click: () => handleViewChange('day'),
          },
          week: {
            text: 'Week',
            click: () => handleViewChange('week'),
          },
          month: {
            text: 'Month',
            click: () => handleViewChange('month'),
          },
        }}
        headerToolbar={{
          left: 'newEvent',
          center: 'prevButton title nextButton',
          right: 'day,week,month',
        }}
        initialView={datePeriodForTechnicianView}
        height={700}
        initialEvents={[]}
        events={mapEventsDataForCalendar(eventsList, selectedInstallers)}
        eventContent={RenderEventContent}
        eventClick={onEventClick}
        scrollTime="08:00:00" // default is '06:00:00'
        allDaySlot={false}
        slotMinTime="08:00:00"
        slotMaxTime="20:01:00"
        select={handleSelect}
        droppable={true}
        drop={handleDrop}
        weekends={isWeekendsVisible}
      />
    </div>
  );
};

export default NewSchedulerCalendar;
