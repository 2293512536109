import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from '@material-ui/core';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';

import {
  CLIENT_DETAILS,
  PROJECT_REPORT,
  scheduleInfoUserAction,
} from '../../../constants';
import MapContainer from '../../shared/SchedulerMap';
import { useAlerts } from '../../shared/Alerts/alertsService';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import { Alert } from '../../shared/Alerts/Alert';
import Notes from '../../ProjectManagement/Notes/Notes';
import Installer from '../../ProjectManagement/ScheduleInfo/Installer';
import { useStyles } from '../../ProjectManagement/Scheduling/Reports/Reports.styles';
import {
  getAssignedAndUnassignedJobsMap,
  getProjectDataById,
  updateProjectScheduleInfo,
  getRescheduleReasonOptions,
  getStoresData,
  getDefaultFilterList,
} from '../../ProjectManagement/Scheduling/Reports/Reports.service';
import {
  checkPermission,
  checkUrlString,
  handleMasterSearch,
  getDefaultTechnicianSchedulerView,
  getDefaultSchedulerView,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import {
  setFilter,
  setNewSchedulerComponent,
  setRtsFilter,
} from '../../../redux/slices/new-scheduler.slice';

import ReadyToScheduleReport from './ready-to-schedule-2';
import SchedulerCalendar from './calendar-2';
import ProjectDetailsViewEditForm from './../ProjectDetailsViewEditForm';
import NewScheduler from './new-schduler';
import CustomFilter from './../CutomFilter/CustomFilter';
import {
  getSearchProjectWorkRoomList,
  getSearchStoreList,
  getUserTypesList,
  getCategoryList,
} from './../schedulerMasterServices';
import { processFilters } from './../helpers/helpers';
import ProjectDetails from './../ProjectDetails';
import SchedulerTabs from './../scheduler-tabs';
import AlertInformation from './../AlertMessage';
import TechnicianFilter from './TechnicianFilter';
import { getAllInstallersList } from './services/calendar';

const Scheduler = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const calendarRef = useRef(null);
  const targetID = 'Schedule';
  const [loader, setLoader] = useState({
    tableLoader: false,
    calendarLoader: false,
  });
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});

  //Copypaset
  const classes = useStyles();
  const { projectNumber } = useParams();
  const [view, setView] = useState(PROJECT_REPORT.CALENDAR);
  const [loading, setLoading] = useState(true);
  const [schedulerLoader, setSchedulerLoader] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [storeNumberOptions, setStoreNumberOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [installerOptions, setInstallerOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [reloadCalender, setReloadCalender] = useState(true);
  const [reloadList, setReloadList] = useState(false);
  const { alert, setAlert } = useAlerts();
  const [calendarFilterData, setCalendarFilterData] = useState();
  const [calendarFullScreen, setCalendarFullScreen] = useState(false);
  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [rescheduleReasonOptions, setRescheduleReasonOptions] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workRoomOptions, setWorkRoomOptions] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [userTypesOptions, setUserTypesOptions] = useState([]);
  const [searchStoreText, setSearchStoreText] = useState('');
  const [searchWorkroomText, setSearchWorkroomText] = useState('');

  const [calendarLoading, setCalendarLoading] = useState(false);
  const [calendarViewAPICall, setCalendarViewAPICall] = useState(false);
  const [openScheduleModel, setOpenScheduleModel] = useState(false);
  const [showTechnicianFilter, setShowTechnicianFilter] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [checkedStores, setCheckedStores] = useState(false);
  const [checkedAssigned, setCheckedAssigned] = useState(1);
  const [checkedUnAssigned, setCheckedUnAssigned] = useState(0);
  const [stores, setStores] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDate, setMapDate] = useState(
    moment(new Date()).format('MM-DD-yyyy hh:mm A')
  );
  const [filterDates, setFilterDates] = useState({
    start_date: moment(new Date()).format('MM-DD-yyyy'),
    end_date: moment(new Date()).format('MM-DD-yyyy'),
  });
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment(new Date()).format('MM-DD-yyyy'),
    endDate: moment(new Date()).format('MM-DD-yyyy'),
    assigned: 1,
    unAssigned: 0,
  });
  const currDate = moment(new Date()).format('MM-DD-YYYY');

  const [RTSSearchQuery, setRTSSearchQuery] = useState({
    limit: 10,
    offset: 0,
    searchQuery: projectNumber,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [dialogSettings, setDialogSettings] = useState({
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  });
  const [storeLoader, setStoreLoader] = useState(false);
  const [userTypeLoader, setUserTypeLoader] = useState(false);
  const [workRoomLoader, setWorkRoomLoader] = useState(false);
  const [selected, setSelected] = useState(null);
  const [initialLoader, setInitialLoader] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler');
  const [schedulerComponent, setSchedulerComponent] = useState(
    isScheduler ? false : true
  );
  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const [isProjectScheduler, setIsProjectScheduler] = useState(false);
  const [datePeriodForTechnicianView, setDatePeriodForTechnicianView] =
    useState(getDefaultSchedulerView() || 'day');
  const [datePeriodForCalenderView, setDatePeriodForCalenderView] = useState(
    getDefaultTechnicianSchedulerView() || 'D'
  );

  const { technicians } = useSelector(state => state.technicians);
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { districts } = useSelector(state => state.districts);
  const { userTypes } = useSelector(state => state.userTypes);
  const { workrooms } = useSelector(state => state.workrooms);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);
  const pathName = window?.location?.pathname;

  const handleSetData = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setData({
        ...data,
        ...params,
      });
    }
  };
  const handleSetFilter = params => {
    console.log('params===>', params);
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      handleSetLoader({
        calendarLoader: true,
      });
      // setFilter({
      //   ...filter,
      //   ...params,
      // });
      setFilter(prevFilter => ({
        ...prevFilter,
        ...params,
      }));

      if (!isScheduler && params?.calendarFilter) {
        setIsFiltersSelected(true);
      }
    }
  };

  const handleSetLoader = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setLoader({
        ...loader,
        ...params,
      });
    }
  };

  const setMasterScheduler = () => {
    setInstallerOptions(technicians?.slice(0, 10));
    setProjectTypeOptions(types);
    setCategoryOptions(categories?.slice(0, 10));
    setStoreOptions(projectStores);
    setDistrictOptions(districts);
    setUserTypesOptions(sourceStatusType);
    setWorkRoomOptions(workrooms);
    setStatusOptions(projectStatusType);
    setStoreNumberOptions(projectStores);
    setUserTypesOptions(userTypes);
  };

  const callGetAllInstallerListApi = async () => {
    //setReadyToScheduleLoader(true);
    const result = await getAllInstallersList();
    if (result) {
      const installHashMap = {};
      result.forEach(e => {
        installHashMap[e?.installer_id] = e;
      });
      handleSetFilter({
        installerMap: installHashMap,
      });
      return;
    }
    throw new Error('installer not found');
  };

  useEffect(() => {
    setMasterScheduler();
  }, [projectStatusType]);

  const handleTechnicianSearch = searchString => {
    const returenedInstaller = handleMasterSearch(
      searchString,
      technicians,
      'full_name'
    );
    setInstallerOptions(returenedInstaller);
  };

  const handleCategorySearch = searchString => {
    const returenedCategory = handleMasterSearch(
      searchString,
      categories,
      'category'
    );
    setCategoryOptions(returenedCategory);
  };

  useEffect(() => {
    setMasterScheduler();
  }, [showTechnicianFilter]);

  const filteredCategoryOptions =
    Array.isArray(categoryOptions) &&
    categoryOptions?.filter(value => value?.category?.trim());

  useEffect(() => {
    const pathname = window?.location?.pathname;
    if (isScheduler && pathname && pathname.split('/')?.length > 2) {
      setIsProjectScheduler(true);
      setSchedulerComponent(true);
    }
  }, []);

  useEffect(() => {
    if (alert.scrollWindow) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [alert.scrollWindow]);

  useEffect(() => {
    if (applyFilter) return;
    if (searchQuery && (view === 'map' || view === 'split')) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
        stores,
        checkedStores,
      });
    }
  }, [searchQuery, view]);

  useEffect(() => {
    if (applyFilter) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
      });
      setApplyFilter(false);
    }
  }, [searchQuery, applyFilter]);

  useEffect(() => {
    if (checkedStores && (view === 'map' || view === 'split')) {
      getStoresData({
        setProjectDataLoading,
        mapData,
        setMapData,
        setStores,
        stores,
      });
    }
  }, [checkedStores]);

  const fetchDefaultFilterList = async () => {
    const response = await getDefaultFilterList();
    let data = [
      {
        filter_id: 'AC',
        filter_name: 'Custom',
      },
      ...(response ?? []),
    ];
    setDefaultFilters(data);
  };
  const handleDefaultFilters = value => {
    const selectedFilters_ = processFilters(value);
    setSelectedFilters(selectedFilters_);
    //dispatch(setRtsFilter(selectedFilters_));
  };

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      const processed = {};
      Object.keys(selectedFilters).map(val => {
        if (
          Array.isArray(selectedFilters[val]) &&
          selectedFilters[val].length > 0
        )
          processed[val] = selectedFilters[val];
      });
      dispatch(setRtsFilter(processed));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (RTSSearchQuery?.searchQuery && reportData?.items) {
      setCalendarFilterData({
        project_type_id: reportData?.items[0]?.project_type_id,
        project_category_id: reportData?.items[0]?.project_category_id,
        project_store_id: reportData?.items[0]?.store_id,
        isJobCoverage: true,
      });
      setSelected(
        mapData.filter(
          map => map.project_id === reportData?.items[0]?.project_id
        )[0]
      );
    }
  }, [reportData, mapData]);
  const hasSetSelectedRow = useRef(false);
  useEffect(() => {
    if (reportData?.items && !hasSetSelectedRow.current) {
      if (isScheduler && pathName && pathName?.split('/')?.length > 2) {
        setSelectedRow({
          client_name: reportData?.items[0]?.client_name,
          project_number: reportData?.items[0]?.project_number,
          store_name: reportData?.items[0]?.store_name,
          project_type: reportData?.items[0]?.project_type,
          po_number: reportData?.items[0]?.po_number,
          store_number: reportData?.items[0]?.store_number,
          category: reportData?.items[0]?.category,
        });
        hasSetSelectedRow.current = true;
      }
    }
  }, [reportData, isScheduler]);

  // Toggle Between Map/Calendar/Split View
  const buttonHandler = buttonName => {
    setView(buttonName);
    setMasterScheduler();
    if (
      [
        PROJECT_REPORT.FULL_CALENDAR,
        PROJECT_REPORT.CALENDAR,
        PROJECT_REPORT.SPLIT,
      ].includes(buttonName)
    ) {
      dispatch(
        setNewSchedulerComponent({
          isWeekendsVisible: false,
        })
      );
      dispatch(
        setNewSchedulerComponent({
          selectedInstallers: [],
          isSelectAllInstallers: false,
        })
      );
      handleSetFilter({
        calendarFilter: {
          ...filter.calendarFilter,
          installerIds: undefined,
        },
      });
      handleSetLoader({
        calendarLoader: true,
      });
    }
  };

  const changeMapDate = day => {
    const currentDate = new Date(mapDate);

    if (day === 'previous') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const updatedDate = currentDate.toISOString().split('T')[0];

    setMapDate(updatedDate);
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: updatedDate,
      endDate: updatedDate,
    }));
  };

  // **** Project Details Form Validation Schema ****
  const ProjectDetailsFormikSchema = Yup.object().shape({
    date_scheduled_start: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
    date_scheduled_end: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
  });
  // **** Project Details Formik Form Values ****
  const projectDetailsFormik = useFormik({
    initialValues: {
      store_number: projectData?.store?.store_number,
      type: projectData?.project_type?.project_type,
      category: projectData?.project_category?.category,
      status: projectData?.status?.status,
      project_number: projectData?.project_number,
      date_sold: projectData?.date_sold,
      client: projectData?.customer
        ? `${projectData?.customer?.first_name} ${projectData?.customer?.last_name}`
        : '',
      address: projectData?.installation_address?.address1,
      mobile_number: projectData?.customer?.customer_phones[0]?.phone_number,
      alternate_number: projectData?.customer?.alternate_phone,
      email: projectData?.customer?.customer_emails[0]?.email,
      ims_status: projectData?.source_status?.status,
      reschedule_reason: projectData?.reschedule_reason,
      rts_follow_up_date: projectData?.rts_follow_up_date,
      date_scheduled_start: projectData?.date_scheduled_start,
      date_scheduled_end: projectData?.date_scheduled_end,
      project_type_id: projectData?.project_type_id,
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateProjectScheduleInfo(
        projectData.project_id,
        {
          reschedule_reason_id:
            projectDetailsFormik?.values?.reschedule_reason?.reason_id,
          rts_follow_up_date: projectDetailsFormik?.values?.rts_follow_up_date,
          date_scheduled_start:
            projectDetailsFormik?.values?.date_scheduled_start,
          date_scheduled_end: projectDetailsFormik?.values?.date_scheduled_end,
          project_type_id: projectDetailsFormik?.values?.project_type_id,
        },
        setProjectDataLoading,
        setAlert,
        setReloadList,
        setIsOpen,
        resetForm
      );
    },
    validationSchema: ProjectDetailsFormikSchema,
    enableReinitialize: true,
  });

  // *** View/Edit Project Details on Marker Click ***
  const viewEditProjectDetails = (
    reschedule_reason,
    source_system_id,
    project_id
  ) => {
    setMasterScheduler();
    setOpenScheduleModel(true);
    projectDetailsFormik?.handleReset();

    getRescheduleReasonOptions(
      PROJECT_REPORT.PROJECT,
      source_system_id,
      setRescheduleReasonOptions,
      value => {}
    );

    getProjectDataById(
      project_id,
      setProjectDataLoading,
      setProjectData,
      setIsOpen,
      setOpenScheduleModel
    );
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Edit Project Info',
    }));
  };

  const installerCalendarFilterPopUp = () => {
    setShowTechnicianFilter(true);
  };

  const handleUserTypeSearch = async event => {
    try {
      const searchString = event;
      if (searchString?.length > 2) {
        setUserTypeLoader(true);
        const searchUserTypes = await getUserTypesList({
          searchString,
          setUserTypeLoader,
        });
        if (searchUserTypes) {
          setUserTypeLoader(false);
          setUserTypesOptions(searchUserTypes);
        }
      } else {
        setUserTypesOptions(userTypes);
      }
    } catch (error) {
      setUserTypeLoader(false);
      console.log(error);
    }
  };
  const fetchStore = async () => {
    if (searchStoreText?.length > 2) {
      const response = await getSearchStoreList({
        searchValue: searchStoreText,
        setStoreLoader,
      });
      if (response && response?.length) {
        setStoreOptions(response);
      }
    } else {
      setStoreOptions(projectStores);
    }
  };

  useEffect(() => {
    fetchStore();
  }, [searchStoreText]);

  const fetchWorkroom = async () => {
    if (searchWorkroomText?.length > 2) {
      const dataList = await getSearchProjectWorkRoomList({
        searchValue: searchWorkroomText,
        setWorkRoomLoader,
      });
      setWorkRoomOptions(dataList);
    } else {
      setWorkRoomOptions(workrooms);
    }
  };
  useEffect(() => {
    fetchWorkroom();
  }, [searchWorkroomText]);

  useEffect(async () => {
    let configData = {};
    try {
      if (CLIENT_DETAILS) {
        try {
          if (
            Array.isArray(CLIENT_DETAILS.tenant_permissions) &&
            CLIENT_DETAILS.tenant_permissions.length
          ) {
            const { included_permissions, allocation_percentage } =
              CLIENT_DETAILS.tenant_permissions[0];

            const allocateItemKeys = Object.keys(scheduleInfoUserAction);
            if (Array.isArray(included_permissions)) {
              const alreadyAllocatePermission = included_permissions?.filter(
                perm => allocateItemKeys.includes(perm)
              );
              configData = {
                ...configData,
                technicianAction:
                  scheduleInfoUserAction[alreadyAllocatePermission?.[0]],
                schedulePercentage: allocation_percentage,
              };
            }
          }
          if (
            CLIENT_DETAILS?.cx_scheduling_module &&
            CLIENT_DETAILS?.job_identifier
          ) {
            configData = {
              ...configData,
              cxSchedulerIcon: CLIENT_DETAILS?.job_identifier,
            };
          }
          if (
            CLIENT_DETAILS?.scheduler_config.calender_view &&
            CLIENT_DETAILS?.scheduler_config.calender_view?.card_visibility
          ) {
            configData = {
              ...configData,
              schedulerCardView:
                CLIENT_DETAILS?.scheduler_config.calender_view?.card_visibility,
            };
          }
          handleSetData(configData);
        } catch (ex) {
          console.log(ex);
        }
      }
    } catch (error) {
      console.log(error);
    }

    await fetchDefaultFilterList();
    await callGetAllInstallerListApi();
    const getCategoryOptions = async () => {
      try {
        const response = await getCategoryList();
        setCategoryOptions(response);
      } catch (error) {
        console.log(error);
      }
    };
    await getCategoryOptions();
  }, []);

  const handleStoresChange = event => {
    setCheckedStores(event.target.checked);
    if (!event.target.checked) {
      const filteredData = mapData?.filter(item => !item.is_store);
      setMapData(filteredData);
    }
  };
  const handleAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: event.target.checked ? 1 : 0,
      unAssigned: searchQuery?.unAssigned,
    }));
    setCheckedAssigned(event.target.checked);
  };
  const handleUnAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: searchQuery?.assigned,
      unAssigned: event.target.checked ? 1 : 0,
    }));
    setCheckedUnAssigned(event.target.checked);
  };

  // Filter Techician
  const handleResetFilters = async () => {
    handleSetFilter({
      calendarFilter: filter?.previousData,
    });
  };

  //copypaste

  return (
    <div className="w-full content-wrapper">
      <div className="grid">
        <div className="col-12">{alert.exists && <Alert />}</div>
      </div>
      <div className="grid w-full grid-nogutter">
        <div className="col-12 md:col-7 flex align-items-center py-0">
          <h2 className="my-0">{isScheduler ? 'Scheduler' : 'Calendar'}</h2>
        </div>
        {isScheduler ? (
          <div className="col-12 md:col-5 py-0">
            {schedulerLoader ? (
              <div className="flex justify-content-end w-12">
                <Skeleton className="mb-2 h-3rem w-12"></Skeleton>
              </div>
            ) : (
              <div className="flex align-items-center">
                <CustomFilter
                  defaultFilters={defaultFilters}
                  handleDefaultFilters={handleDefaultFilters}
                  setSelectedFilters={setSelectedFilters}
                  fetchDefaultFilterList={fetchDefaultFilterList}
                  setCalendarFilterData={setCalendarFilterData}
                />
              </div>
            )}
          </div>
        ) : null}
        {!isProjectScheduler ? (
          <div className="col-12 xl:col-12 mb-2">
            {!calendarFullScreen && (
              <AlertInformation view={view} isScheduler={isScheduler} />
            )}
          </div>
        ) : null}
      </div>
      <div className="schedule-container ">
        {isScheduler && !calendarFullScreen ? (
          <ReadyToScheduleReport
            tableRef={tableRef}
            calendarRef={calendarRef}
            targetID={targetID}
            filter={filter}
            data={data}
            loader={loader}
            handleSetFilter={handleSetFilter}
            handleSetData={handleSetData}
            handleSetLoader={handleSetLoader}
          />
        ) : null}

        {isScheduler && filter?.selectedRow && !calendarFullScreen ? (
          <ProjectDetails selectedRow={filter?.selectedRow} />
        ) : null}

        <SchedulerTabs
          buttonHandler={buttonHandler}
          initialLoader={false}
          view={view}
          isScheduler={isScheduler}
          calendarFullScreen={calendarFullScreen}
          installerCalendarFilterPopUp={installerCalendarFilterPopUp}
          schedulerComponent={true}
          isFiltersSelected={
            isScheduler ? !!filter?.selectedRow : isFiltersSelected
          }
        />
      </div>
      <div
        className={`bg-white w-12 px-2 ${!isScheduler ? 'calendar-page-height' : 'calendar-full-height'}`}
      >
        {view === PROJECT_REPORT.FULL_CALENDAR && (
          <NewScheduler
            tableRef={tableRef}
            calendarRef={calendarRef}
            targetID={targetID}
            filter={filter}
            data={data}
            loader={loader}
            handleSetFilter={handleSetFilter}
            handleSetData={handleSetData}
            handleSetLoader={handleSetLoader}
            statusOptions={statusOptions}
            setCalendarFullScreen={setCalendarFullScreen}
            calendarFullScreen={calendarFullScreen}
            view={view}
          />
        )}
      </div>
      <div
        className={`grid w-12 grid-nogutter ${calendarFullScreen ? 'calendar-fullScreen' : null} ${!isScheduler ? 'scheduler-page-height' : 'scheduler-full-height'}`}
      >
        {view === PROJECT_REPORT.CALENDAR || view === PROJECT_REPORT.SPLIT ? (
          <div
            className={`col-12 ${
              view === PROJECT_REPORT.SPLIT && !calendarFullScreen
                ? 'xl:col-6 border-right-1 border-200'
                : 'xl:col-12 p-0'
            } `}
          >
            {/* {(schedulerComponent && isScheduler) || isFiltersSelected ? ( */}
            {filter?.calendarFilter && (
              <div
                className={`bg-white ${isScheduler ? 'scheduler-page' : 'calendar-component'}`}
              >
                <div className="flex flex-column">
                  <div className="col-12 p-0">
                    <SchedulerCalendar
                      tableRef={tableRef}
                      calendarRef={calendarRef}
                      targetID={targetID}
                      filter={filter}
                      data={data}
                      loader={loader}
                      handleSetData={handleSetData}
                      handleSetLoader={handleSetLoader}
                      statusOptions={statusOptions}
                      setCalendarFullScreen={setCalendarFullScreen}
                      calendarFullScreen={calendarFullScreen}
                      view={view}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* // ) : null} */}
          </div>
        ) : null}
        {(view === PROJECT_REPORT.MAP || view === PROJECT_REPORT.SPLIT) &&
          !calendarFullScreen && (
            <div
              className={`col-12 xl:col-${
                view === PROJECT_REPORT.SPLIT ? 6 : 12
              }`}
            >
              <div
                className="bg-white"
                classes={{ root: classes.mapPaper }}
                elevation={0}
              >
                <div className="flex flex-column">
                  <div className="col-12 mb-4">
                    <MapContainer
                      lat={59.95}
                      long={30.33}
                      markers={mapData}
                      viewEditProjectDetails={viewEditProjectDetails}
                      categoryOptions={categoryOptions}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      mapDate={mapDate}
                      setMapDate={setMapDate}
                      changeMapDate={changeMapDate}
                      selected={selected}
                      setSelected={setSelected}
                      showErrorMsg={showErrorMsg}
                      handleStoresChange={handleStoresChange}
                      handleAssignedChange={handleAssignedChange}
                      handleUnAssignedChange={handleUnAssignedChange}
                      checkedAssigned={checkedAssigned}
                      checkedUnAssigned={checkedUnAssigned}
                      checkedStores={checkedStores}
                      setFilterDates={setFilterDates}
                      currDate={currDate}
                      handleCategorySearch={handleCategorySearch}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      <GenericDialog
        isOpen={isOpen}
        dialogWidth="lg"
        handleClose={() => {
          setIsOpen(false);
          projectDetailsFormik?.handleReset();
        }}
        handleSave={projectDetailsFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
        }
        disabledButton1={false}
      >
        <div className='col-12 className="flex flex-column gap-2'>
          <div className="grid">
            <div className="col-12 p-0">{alert.exists && <Alert />}</div>
            <div className="col-12 text-right">
              <Link
                to={`/project/view/${projectData?.project_id}`}
                target="_blank"
                className="border-1 px-3 py-2 border-round-md no-underline border-primary text-primary"
              >
                View Project
              </Link>
            </div>
            <div className="col-12">
              <form onSubmit={projectDetailsFormik.handleSubmit}>
                <ProjectDetailsViewEditForm
                  projectDetailsFormik={projectDetailsFormik}
                  rescheduleReasonOptions={rescheduleReasonOptions}
                />
              </form>
            </div>
            <div className="col-12">
              {isOpen && (
                <Installer
                  formik={{
                    values: {
                      project_id: projectData.project_id,
                      date_scheduled:
                        projectDetailsFormik?.values?.date_scheduled,
                      proj_end_date:
                        projectDetailsFormik?.values?.project_end_date,
                    },
                  }}
                  setReloadCalender={setReloadCalender}
                  reloadCalender={reloadCalender}
                  projectId={projectData.project_id}
                  schedulerEditPermission={
                    !checkPermission(
                      permissions?.viewEditProject?.actionEditScheduler
                    )
                  }
                  installerOptions={installerOptions}
                />
              )}
            </div>
            <div className="col-12">
              <Notes
                formik={{ values: { project_id: projectData.project_id } }}
                action=""
                schedulerEditPermission={
                  !checkPermission(
                    permissions?.viewEditProject?.actionEditScheduler
                  )
                }
              />
            </div>
          </div>
        </div>
      </GenericDialog>
      <TechnicianFilter
        setShowTechnicianFilter={() => {}}
        handleClosePopup={() => {}}
        showTechnicianFilter={() => {}}
        installerFormik={{}}
        handleResetFilters={handleResetFilters}
        installerOptions={[]}
        projectTypeOptions={[]}
        storeOptions={[]}
        categoryOptions={[]}
        districtOptions={[]}
        userTypesOptions={[]}
        workRoomOptions={[]}
        setSearchWorkroomText={() => {}}
        setSearchStoreText={() => {}}
        handleUserTypeSearch={() => {}}
        storeLoader={false}
        workRoomLoader={false}
        userTypeLoader={false}
        calendarLoading={false}
        handleTechnicianSearch={() => {}}
        handleCategorySearch={() => {}}
        handleSetFilter={handleSetFilter}
        filter={filter}
      />
    </div>
  );
};

export default Scheduler;
